import React from "react";
import "./../../styles/Form/form.css";
/**
 * Component input checkBox with color of the theme
 *
 * @param {{value: boolean, onChange: function}} props
 * @returns component
 * @author Johns David
 */

function CheckBox({
  id = null,
  value = false,
  label = null,
  onChange = (e) => {},
  isDisale = false,
  labelClass = "",
}) {
  const handleChange = (e) => {
    const newValue = e.target.checked;
    onChange(newValue);
  };

  return (
    <div className="form-check">
      <input
        className="form-check-input cursor-pointer"
        id={id}
        name={id}
        type="checkbox"
        checked={value}
        onChange={handleChange}
        disabled={isDisale}
      />
      {label != null ? (
        <label
          htmlFor={id}
          className={
            "form-label input-label-size position-relative cursor-pointer " +
            labelClass
          }
        >
          {label}
        </label>
      ) : null}
    </div>
  );
}

export default CheckBox;
