import React from "react";

const RadioButton = ({ name, label, options, selected, onChange }) => {
  const handleOptionChange = (event) => {
    onChange(parseInt(event.target.value));
  };

  return (
    <div className="input-group mb-3 me-2 col">
      {options.map((option) => (
        <div key={option.key} className="form-check form-check-inline ">
          <input
            className="form-check-input"
            type="radio"
            id={`radio-${option.key}`}
            name={name}
            value={option.key}
            checked={selected === option.key}
            onChange={handleOptionChange}
          />
          <label
            className="form-check-label text-muted"
            htmlFor={`radio-${option.key}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
