import React, { useEffect, useState } from "react";
import "../../styles/Form/input.css";
import "../../styles/Pages/login.css";

const TextArea = ({
  fieldBlockClass = "",
  value = "",
  fieldClass = "",
  id = "myTextArea",
  name = "myTextArea",
  label = "",
  labelClass = "",
  isRequired = false,
  rows = 3,
  placeholder = null,
  onChange = (e) => {},
}) => {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
    if (isRequired && value.length > 0) {
      setData((v) => ({ ...v, errorMessage: "" }));
    }
  };

  return (
    <>
      <div className={"mb-3 " + fieldBlockClass ?? ""}>
        <label
          htmlFor={id ?? "myTextArea"}
          className={
            "form-label input-label-size position-relative " + labelClass ?? ""
          }
        >
          {label != "" ? label : "My text area"}
          {isRequired === true && (
            <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
              *
            </span>
          )}
        </label>
        <textarea
          className={"form-control input-label-size " + fieldClass}
          id={id}
          name={name}
          rows={rows}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        />
      </div>
    </>
  );
};

export default TextArea;
