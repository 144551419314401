import React, { useEffect, useRef, useState } from "react";
import "../../styles/Tables/tableContent.css";
import TableTd from "./TableTd";
import { useDispatch, useSelector } from "react-redux";
import {
  initData,
  selectCheck,
  selectCheckAll,
  updateNumberFilter,
  setCurrentPage,
  setTableContent,
  setTableHeadFilter,
  updateIndexFilter,
} from "../../store/table/tableSlice";
import CheckBox from "../Form/CheckBox";
import TableFooter from "./TableFooter";
import useSearchTable from "../../hooks/table/useSearchTable";

function TableContent({
  headers,
  contents = [],
  keys = [],
  search_key = [],
  search_value = "",
  filter_data = [],
  filter_ref = "statut",
  active_index_filter = 0,
  object_serach_key = [],
}) {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.table.currentPage);
  const itemsPerPage = 10;
  const { searchValue, searchChange } = useSearchTable(search_value);
  useEffect(() => {
    dispatch(
      initData({
        contents,
        search_key,
        search_value,
        filter_data,
        filter_ref,
        active_index_filter,
        object_serach_key,
      })
    );
    dispatch(updateNumberFilter());
    dispatch(setCurrentPage(0));
    if (active_index_filter == 0) {
      searchChange(search_value);
    }
  }, [contents]);

  const tableData = useSelector((state) => state.table.data);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentItems(tableData.slice(start, end));
    setTimeout(() => {
      let heigthDiv = elementRef?.current?.clientHeight;
      setHeight(heigthDiv ? heigthDiv : 0);
    }, 100);
  }, [tableData, currentPage, itemsPerPage]);

  const handleSelectCheck = (index) => {
    dispatch(selectCheck(index));
  };

  const handleSelectAllCheck = (isChecked) => {
    if (currentItems.length > 0) {
      const start = currentPage * itemsPerPage;
      const end = start + itemsPerPage;
      dispatch(selectCheckAll({ isChecked, start, end }));
    }
  };

  const heigthTableHeadControl = useSelector(
    (state) => state.table.heigthTableHeadControl
  );

  const heigthTableHeadFilter = useSelector(
    (state) => state.table.heigthTableHeadFilter
  );

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  const filter_state = useSelector((state) => state.filterTable.filter_state);

  useEffect(() => {
    if (filter_state.length == 0 && filter_data.length == 0) {
      dispatch(setTableHeadFilter(0));
    }
  }, [filter_data, filter_state]);

  const areAllChecked =
    currentItems.length > 0 && currentItems.every((item) => item.check);

  return (
    <>
      <div
        className="parent-t"
        style={{
          marginTop: `${heigthTableHeadControl + heigthTableHeadFilter}px`,
        }}
        ref={elementRef}
      >
        <table className="table bsl-table-global mb-0">
          <thead>
            <tr className="table-active">
              {keys.map((key, index) => (
                <th key={index} className={`w-t-${headers[key]?.width}`}>
                  {index === 0 && key == "check" ? (
                    <CheckBox
                      value={areAllChecked}
                      onChange={(e) => handleSelectAllCheck(e)}
                    />
                  ) : (
                    headers[key]?.value
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <TableTd
                  item={item}
                  keys={keys}
                  index={currentPage * itemsPerPage + index}
                  onSelectCheck={handleSelectCheck}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TableFooter
        marginTop={`${
          heigthTableHeadControl +
          heigthTableHeadFilter +
          height -
          (heigthTableHeadFilter > 0 ? 136 : 0)
        }px`}
        currentPage={currentPage}
        setCurrentPage={(pageNumber) => dispatch(setCurrentPage(pageNumber))}
        totalItems={tableData.length}
        itemsPerPage={itemsPerPage}
      />
    </>
  );
}

export default TableContent;
