import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { UserProvider, useUser } from "./UserContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LoginPage from "./components/Pages/LoginPage";
import ForgotPassword from "./components/Pages/ForgotPassword";
import HomePage from "./components/Pages/HomePage";
import ClientPage from "./components/Pages/ClientPage";
import StPage from "./components/Pages/StPage";
import DemandePage from "./components/Pages/DemandePage";
import AdminPage from "./components/Pages/AdminPage";

import CommandePage from "./components/Pages/CommandePage";
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
};

const PublicRoute = ({ children }) => {
  if (getCookie("user")) {
    return <Navigate to="/Clients" />;
  }
  return children;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/Accueil"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Clients"
          element={
            <ProtectedRoute>
              <ClientPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Sous-traitants"
          element={
            <ProtectedRoute>
              <StPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Demande"
          element={
            <ProtectedRoute>
              <DemandePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Commande"
          element={
            <ProtectedRoute>
              <CommandePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Admin"
          element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

function AppWrapper() {
  return (
    <CookiesProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </CookiesProvider>
  );
}

export default AppWrapper;
