import React from "react";

const TabPane = ({ carouselItems = [], changeCarouselItems = (e) => {} }) => {
  return (
    <>
      {carouselItems.length > 0 ? (
        <div className="d-flex p-0 m-0 h-100" key={0}>
          {carouselItems.map((item, item_index) => (
            <div
              className={`col-md-6 col-sm-12 border-bottom border-4 text-body-secondary  text-center ${
                item.isActive == true ? "border-primary " : "border-opacity-0"
              }`}
              style={{ height: "100%" }}
              onClick={() => {
                changeCarouselItems(item_index);
              }}
              key={"tab_pane_" + item_index}
            >
              {item.itemText}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default TabPane;
