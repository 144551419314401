import React, { useCallback, useEffect, useState } from "react";
import Admin from "../root/Admin";
import TableTitle from "../Tables/TableTitle";
import Table from "../Tables/Table";
import AddUser from "../Modals/Insert/admin/AddUser";
import { FetchData } from "../../hooks/FetchData";
import { useDispatch, useSelector } from "react-redux";
import { initDropdown } from "../../store/searchDropdownSlice";
import Swal from "sweetalert2";
import { initUpdate } from "../../store/table/btnIconSlice";
import { handle_filter } from "../../store/table/tableSlice";
import { initFilter, setFilterOnly } from "../../store/table/filterTableSlice";

import AdminModalsSelector from "../Modals/Insert/admin/AdminModalsSelector";
import Cookies from "js-cookie";
import { useAccess } from "../../hooks/useAccess";
import { RedirectUnallowed } from "../../hooks/RedirectUnallowed";
import { Button } from "bootstrap";
import FormButton from "../Form/FormButton";
const AdminPage = () => {
  //Permissions
  const can_access_admin_page = useAccess(45);
  const can_create_new_user = useAccess(46);
  const can_delete_user = useAccess(47);
  const can_see_user_infos = useAccess(83);
  const can_view_users = useAccess(55);
  const can_create_new_role = useAccess(50);
  const can_delete_role = useAccess(51);
  const can_see_role_infos = useAccess(84);
  const can_view_roles = useAccess(57);

  const userCookie = Cookies.get("userData");
  const connectedUser = JSON.parse(
    typeof userCookie === "undefined" ? '{"id":0}' : userCookie
  );

  const ref_name_page = "AdminPage";
  const table_key = ["check", "username", "role", "email", "numero", "delete"];
  //recuperation de la liste des users
  const [users, setUsers] = useState([]);

  const [user, setUser] = useState([]);
  const [super_admins, set_super_admins] = useState([]);
  const [civilities, setCivilities] = useState([]);

  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const data_store = useSelector((state) => state.table.data);

  const getUsers = async () => {
    const { loading, data, errors } = await FetchData("/api/user", {
      method: "GET",
    });
    setUsers(data.data.users);
    set_super_admins(
      data.data?.users
        ?.filter((user) => user?.id_role === 1)
        .map((user) => user?.id)
    );
    setRoles(data.data.roles);
    setCivilities(data.data.civilites);
    setPermissions(data.data.permissions);

    return { loading, data, errors };
  };

  useEffect(() => {
    getUsers();
  }, []);

  const modal_data = {
    type: "modal",
    modalId: "addCollaboratorModal",
    modalClass: "modal-xl",
  };

  const dispatch = useDispatch();

  const data_action_td = useSelector((state) => state.btnIconSlice.data);

  const dropdown_value_search = useSelector(
    (state) => state.dropdown.value_search[ref_name_page]
  );

  const [isShowModal, setIsShowModal] = useState(false);

  const [actual_key, set_actual_key] = useState(
    useSelector((state) => state.filterTable.actual_key)
  );

  //delete user
  const deleteUser = async (id) => {
    const { loading, data, errors } = await FetchData(`/api/user/${id}`, {
      method: "DELETE",
    });
    return { loading, data, errors };
  };

  //delete user
  const deleteRole = async (id) => {
    const { loading, data, errors } = await FetchData(
      `/api/user/user-role/${id}`,
      {
        method: "DELETE",
      }
    );
    return { loading, data, errors };
  };

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Êtes-vous sûr?",
        text: `Voulez vous supprimer ${
          data_action_td.action_name === "delete_user"
            ? "cet utilisateur"
            : "ce profil"
        }?`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui, supprimer!",
        confirmButtonColor: "#0e3feb",
        reverseButtons: true,
      }).then((result) => {
        if (result.isDismissed) {
          dispatch(
            initUpdate({
              action_name: "",
              action_value: -1,
            })
          );
        }
        if (result.isConfirmed) {
          const { loading, data, errors } =
            data_action_td.action_name === "delete_user"
              ? deleteUser(id)
              : deleteRole(id);
          if (!errors) {
            dispatch(
              initUpdate({
                action_name: "",
                action_value: -1,
              })
            );
            Swal.fire({
              title: "Supprimé!",
              text: `${
                data_action_td.action_name === "delete_user"
                  ? "L'utilisateur"
                  : "Le profil"
              } a été supprimé.`,
              icon: "success",
              position: "center",
              showConfirmButton: false,
              timer: 3000,
            });
            getUsers();
          } else {
            Swal.fire({
              title: "Erreur",
              text: `Une erreur s'est produite lors de la suppression de ${
                data_action_td.action_name === "delete_user"
                  ? "cet utilisateur"
                  : "ce profil"
              }.`,
              icon: "error",
              confirmButtonColor: "#0e3feb",
              confirmButtonText: "OK",
              timer: 3000,
            });
          }
        }
      });
    },
    [data_action_td.action_name]
  );

  // delete users
  const deleteUsers = async (userIds) => {
    const { loading, data, errors } = await FetchData("/api/user", {
      method: "DELETE",
      body: JSON.stringify({ userIds }),
    });
    return { loading, data, errors };
  };

  const filterView = (can_view_users, can_view_roles) => {
    let filter = [];
    if (can_view_users) {
      filter = [
        ...filter,
        {
          label: "Utilisateurs",
          key: "utilisateur",
          selected: can_view_users && actual_key == "utilisateur",
        },
      ];
    }
    if (can_view_roles) {
      filter = [
        ...filter,
        {
          label: "Profils",
          key: "profil",
          selected:
            can_view_roles && (actual_key == "profil" || !can_view_users),
        },
      ];
    }
    return filter;
  };

  const filterAdminTableKey = (first_table_key = [], can_delete) => {
    let table_key = first_table_key;
    if (can_delete) {
      table_key = [...table_key, "delete"];
    }
    return table_key;
  };

  const handleDeletes = () => {
    //recuperation des utilisateur a supprimer
    const userIds = data_store
      .filter((user) => user.check === true)
      .map((user) => user.id);

    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Voulez vous vraiment supprimer ces utilisateurs ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed && userIds) {
        const { loading, data, errors } = deleteUsers(userIds);
        if (!errors) {
          Swal.fire({
            title: "Supprimé!",
            text: "Les utilisateurs ont été supprimé.",
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          getUsers();
        } else {
          Swal.fire({
            title: "Erreur",
            text: "Une erreur s'est produite lors de la suppression des utilisateurs.",
            icon: "error",
            confirmButtonColor: "#0e3feb",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  //get user for uptade
  const getUser = async (userId) => {
    const { loading, data, errors } = await FetchData(`/api/user/${userId}`, {
      method: "GET",
    });
    setUser(data.data);
    return { loading, data, errors };
  };

  //get user_role for uptade
  const getUserRole = async (userRoleId) => {
    const { loading, data, errors } = await FetchData(
      `/api/user/user-role/${userRoleId}`,
      {
        method: "GET",
      }
    );
    setRole(data.data);
    return { loading, data, errors };
  };

  useEffect(() => {
    if (actual_key === "utilisateur") {
      // Action delete here
      if (
        data_action_td.action_name === "delete_user" &&
        data_action_td.action_value > 0 &&
        (super_admins.indexOf(data_action_td.action_value) > -1
          ? super_admins.indexOf(connectedUser?.id) > -1
          : true)
      ) {
        handleDelete(data_action_td.action_value);
      }
      // Action update
      if (
        data_action_td.action_name === "update_user" &&
        data_action_td.action_value > 0 &&
        can_see_user_infos
      ) {
        getUser(data_action_td.action_value).then(() => {
          setIsShowModal(true);
        });
        dispatch(
          initUpdate({
            action_value: -1,
          })
        );
      }
    }
    if (actual_key === "profil") {
      // Action delete here
      if (
        data_action_td.action_name === "delete_user_role" &&
        data_action_td.action_value > 0 &&
        (data_action_td.action_value === 1
          ? super_admins.indexOf(connectedUser?.id) > -1
          : true)
      ) {
        handleDelete(data_action_td.action_value);
      }
      // Action update
      if (
        data_action_td.action_name === "update_user_role" &&
        data_action_td.action_value > 0 &&
        can_see_role_infos
      ) {
        getUserRole(data_action_td.action_value).then(() => {
          setIsShowModal(true);
        });
        dispatch(
          initUpdate({
            action_value: -1,
          })
        );
      }
    }
  }, [data_action_td.action_value]);

  useEffect(() => {
    dispatch(
      handle_filter({
        dropdown_value_search: dropdown_value_search,
        profile_list: roles,
        colone_name_filter: "id_role",
      })
    );
  }, [dropdown_value_search]);

  const closeModal = useCallback(() => {
    setUser({});
    setRole({});
    setIsShowModal(false);
  }, []);
  const showModal = useCallback(() => {
    setIsShowModal(true);
  }, []);

  useEffect(() => {
    dispatch(
      initDropdown({
        reference_name: ref_name_page,
        value_search: ["All"],
        data_label: {
          icon: "fa-solid fa-user",
          title: "Tous les profils",
          select_all: "Tous les profils",
        },
      })
    );

    dispatch(
      initFilter({
        actual_key:
          can_view_roles && !can_view_users ? "profil" : "utilisateur",
        filter_state: [
          {
            label: "Utilisateur",
            key: "utilisateur",
            selected: actual_key == "utilisateur",
          },
          {
            label: "Profil",
            key: "profil",
            selected: actual_key == "profil",
          },
        ],
        data_table: {
          utilisateur: {
            table_key: filterAdminTableKey(
              ["check", "username", "role", "email", "numero"],
              can_delete_user
            ),
            table_headers: {
              check: { value: "checkbox", width: 20 },
              username: { value: "Nom", width: 150 },
              role: { value: "Profil", width: 150 },
              email: { value: "Mail", width: 150 },
              numero: { value: "N° Collaborateur", width: 100 },
              delete: { value: "", width: 50 },
            },
            data_table: users,
            list_not_normal: ["check", "username", "delete"],
            object_serach_key: ["username"],
            data_dropdown_list: roles,
            search_key: ["username", "role", "email", "numero"],
            data_label: {
              title: "Utilisateurs",
              select_all: "Utilisateurs",
            },
          },
          profil: {
            table_key: filterAdminTableKey(
              ["check", "user_role_name"],
              can_delete_role
            ),
            table_headers: {
              check: { value: "checkbox", width: 20 },
              user_role_name: { value: "Nom", width: 150 },
              delete: { value: "", width: 50 },
            },
            data_table: roles,
            list_not_normal: ["check", "delete"],
            object_serach_key: ["user_role_name"],
            data_dropdown_list: roles,
            search_key: ["user_role_name"],
            data_label: {
              title: "Profils",
              select_all: "Profils",
            },
          },
        },
      })
    );
    dispatch(setFilterOnly(filterView(can_view_users, can_view_roles)));
  }, [users]);

  return can_access_admin_page ? (
    <Admin>
      <AdminModalsSelector
        permissions={permissions}
        user={user}
        connectedUser={connectedUser}
        getUsers={getUsers}
        getUserRole={getUserRole}
        isShowModal={isShowModal}
        actual_key={actual_key}
        closeModal={closeModal}
        roles={roles}
        role={role}
        civilities={civilities}
      />

      <TableTitle
        title="Admin"
        button_name={
          actual_key === "utilisateur"
            ? "Nouveau collaborateur"
            : "Nouveau Profil"
        }
        show_button={
          actual_key === "utilisateur"
            ? can_create_new_user
            : can_create_new_role
        }
        modalId={modal_data?.modalId}
        onClick={showModal}
      />
      {can_view_users || can_view_roles ? (
        <InitAdmin
          roles={roles}
          set_actual_key={set_actual_key}
          onDelete={handleDeletes}
        />
      ) : (
        <FormButton
          buttonValue={`Permuter vers ${
            actual_key === "utilisateur" ? "profil" : "utilisateur"
          }`}
          buttonClass=" btn btn-secondary border border-0 rounded rounded-2"
          hasIcon={true}
          iconClass=" fas fa-repeat me-2"
          closeModal={false}
          iconPlace="left"
          buttonOnClick={() => {
            set_actual_key((old_key) =>
              old_key === "utilisateur" ? "profil" : "utilisateur"
            );
          }}
        />
      )}

      {isShowModal == true ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}
    </Admin>
  ) : (
    RedirectUnallowed("/Accueil")
  );
};

function InitAdmin({
  roles = [],
  set_actual_key = () => {},
  onDelete = () => {},
}) {
  const dispatch = useDispatch();
  const ref_name_page = "AdminPage";
  const actual_key = useSelector((state) => state.filterTable.actual_key);
  const data_table = useSelector((state) => state.filterTable.data_table);

  const can_delete_user = useAccess(47);
  const can_search_user = useAccess(56);
  const can_filter_user_by_profil = useAccess(54);
  const can_view_users = useAccess(55);
  const can_delete_role = useAccess(51);
  const can_search_role = useAccess(53);
  const can_view_roles = useAccess(57);
  useEffect(() => {
    dispatch(
      initDropdown({
        reference_name: ref_name_page,
        value_search: { AdminPage: ["All"] },
        data_label: {
          icon: "fa-solid fa-user",
          title: "Tous les profils",
          select_all: "Tous les profils",
        },
      })
    );
  }, [actual_key]);

  const data_store = useSelector((state) => state.table.data);
  const getLength = (cle) => {
    return actual_key == cle
      ? data_store.length
      : data_table[cle]?.data_table?.length;
  };

  const filterView = (can_view_users, can_view_roles) => {
    let filter = [];
    if (can_view_users) {
      filter = [
        ...filter,
        {
          label: "Utilisateurs",
          key: "utilisateur",
          selected: can_view_users && actual_key == "utilisateur",
          number: getLength("utilisateur"),
        },
      ];
    }
    if (can_view_roles) {
      filter = [
        ...filter,
        {
          label: "Profils",
          key: "profil",
          selected:
            can_view_roles && (actual_key == "profil" || !can_view_users),
          number: getLength("profil"),
        },
      ];
    }
    return filter;
  };

  useEffect(() => {
    dispatch(setFilterOnly(filterView(can_view_users, can_view_roles)));
    set_actual_key(actual_key);
  }, [actual_key, data_store]);

  return (
    <>
      {Object.keys(data_table).length !== 0 && (
        <Table
          data_table={data_table[actual_key]?.data_table}
          table_headers={data_table[actual_key].table_headers}
          table_key={data_table[actual_key].table_key}
          search_key={data_table[actual_key].search_key}
          list_not_normal={data_table[actual_key].list_not_normal}
          object_serach_key={data_table[actual_key].object_serach_key}
          show_client_filter={
            actual_key === "utilisateur" ? can_filter_user_by_profil : false
          }
          show_date_filter={false}
          dropdownLabel="label"
          data_dropdown_list={
            actual_key === "utilisateur"
              ? data_table[actual_key].data_dropdown_list
              : roles
          }
          ref_name_page={ref_name_page}
          label_delete="Supprimer la selection"
          goUrl={true}
          filter_ref={null}
          data_btn_control={{
            show_export_btn: false,
            show_delete_btn:
              actual_key === "utilisateur" ? can_delete_user : false,
            show_change_status_btn: false,
          }}
          show_search_input={
            actual_key === "utilisateur" ? can_search_user : can_search_role
          }
          onDelete={onDelete}
        />
      )}
    </>
  );
}

export default AdminPage;
