import React from "react";

const ErrorList = ({ errorList = [] }) => {
  return (
    <>
      {errorList?.length > 0 ? (
        <div className="p-3 ps-4">
          <div className=" danger bg-danger-subtle border-start border-danger border-3 rounded rounded-1 p-2">
            <h2 className="fs-6">
              <i className="fa-solid fa-circle-exclamation text-danger me-1 "></i>
              Erreurs
            </h2>
            <ul>
              {errorList.map((d, index) => (
                <li className="input-label-size " key={"error_" + index}>
                  {d}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ErrorList;
