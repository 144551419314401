import React from "react";

const FormCard = ({ children, title = "" }) => {
  return (
    <div className=" container-fluid mb-3 pb-1 p-0 card ">
      <div className="card-header section-label-size ps-4 fw-bold">{title}</div>
      <div className="card-body p-0">{children}</div>
    </div>
  );
};

export default FormCard;
