import { useEffect, useState } from "react";
import { useToggle } from "react-use";
/**
 * Usefull state for daterange with personal label
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns object
 * @author Johns David
 */
export default function useValidateDate(startDate, endDate) {
  const getLastDateOfMonth = (date = new Date()) => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const res = lastDay.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
    });
    return res.charAt(0).toUpperCase() + res.slice(1);
  };
  const [label, setLabel] = useState(getLastDateOfMonth(startDate));

  const [toggle, setToggle] = useToggle(false);
  const [dateRange, setdateRange] = useState({
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  });

  useEffect(() => {
    setLabel(getLastDateOfMonth(dateRange.startDate));
  }, [dateRange]);

  const [state, setState] = useState({
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  });

  const handleValid = () => {
    setToggle(false);
    setdateRange({ ...state });
  };
  const clickShowDropdown = () => {
    setToggle(true);
    setState(dateRange);
  };

  return {
    label,
    dateRange,
    state,
    toggle,
    setToggle,
    handleValid,
    setState,
    clickShowDropdown,
  };
}
