import React, { useState, useEffect } from "react";

const SelectBox = ({
  blockClass = "",
  id = "select",
  labelClass = "",
  label = "labelName",
  selectClass = "",
  isRequired = false,
  isDisabled = false,
  selectOptions = [],
  cle = "id",
  value = "label",
  value_selected = 0,
  errorMessage = "",
  placeholder = "",
  onChange = (e) => {},
}) => {
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    setInputError(errorMessage);
  }, [errorMessage]);

  const handleChange = (e) => {
    onChange(e.target.value);
    if (isRequired && errorMessage !== "") {
      setInputError("");
    }
  };

  return (
    <>
      <div className={"mb-3 " + blockClass}>
        <label
          htmlFor={id}
          className={
            "form-label input-label-size position-relative " + labelClass
          }
        >
          {label}
          {isRequired && (
            <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
              *
            </span>
          )}
        </label>
        <select
          className={
            "custom-select-box bg-light" +
            selectClass +
            (inputError?.length > 5 ? " invalid-input-colors " : " ")
          }
          id={id}
          name={id}
          //required={isRequired}
          onChange={handleChange}
          value={value_selected !== 0 ? value_selected : ""}
          disabled={isDisabled}
        >
          {/* Option pour le placeholder */}
          <option value="" disabled>
            {placeholder}
          </option>

          {selectOptions?.map((d, index) => (
            <option
              value={d[cle]}
              key={index}
              // selected={
              //   d[cle] === value_selected
              //     ? "selected" && value_selected > 0
              //     : null
              // }
            >
              {d[value]}
            </option>
          ))}
        </select>
        {inputError?.length > 0 && (
          <span className="input-error-message">
            <i className="fa-solid fa-circle-exclamation text-danger me-2"></i>
            {inputError}
          </span>
        )}
      </div>
    </>
  );
};

export default SelectBox;
