import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  filter_state: [],
  data_table: {},
  actual_key: "",
};
const filterTableSlice = createSlice({
  name: "filterTable",
  initialState,
  reducers: {
    initFilter: (state, action) => {
      state.filter_state = action.payload.filter_state;
      state.data_table = action.payload.data_table;
      state.actual_key = action.payload.actual_key;
    },
    setFilterOnly: (state, action) => {
      state.filter_state = action.payload;
    },
    selectedFilter: (state, action) => {
      state.filter_state = state.filter_state.map((v) => ({
        ...v,
        selected: action.payload == v.key,
      }));
      state.actual_key = action.payload;
    },
  },
});

export const { initFilter, selectedFilter, setFilterOnly } =
  filterTableSlice.actions;
export default filterTableSlice.reducer;
