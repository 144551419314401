import React from "react";

const ListItems = ({ children }) => {
  return (
    <>
      <li className="list-group-item ">{children}</li>
    </>
  );
};

export default ListItems;
