import React from "react";
import AddUser from "./AddUser";
import UpdateRole from "./UpdateRole";
import AddRole from "./AddRole";

const AdminModalsSelector = ({
  permissions = [],
  connectedUser = {},
  isShowModal = false,
  closeModal = () => {},
  actual_key = "utilisateur", //handle modal that is shown
  user = {},
  role = {},
  roles = [],
  civilities = [],
  getUsers = () => {},
  getUserRole = () => {},
}) => {
  const admin_modals = {
    utilisateur: (
      <AddUser
        connectedUser={connectedUser}
        user={user}
        getUsers={getUsers}
        modalData={{
          type: "modal",
          modalId: "addCollaboratorModal",
          modalClass: "modal-xl",
        }}
        isShowModal={isShowModal}
        handleCloseModal={closeModal}
        list_role={roles}
        list_civilities={civilities}
      />
    ),
    profil: (
      <React.Fragment>
        {isEmptyObject(role) === true ? (
          <AddRole
            getUsers={getUsers}
            connectedUser={connectedUser}
            isShowModal={isShowModal}
            handleCloseModal={closeModal}
            modalData={{
              type: "modal",
              modalId: "addProfilModal",
              modalClass: "modal-xl",
            }}
          />
        ) : (
          <UpdateRole
            permissions={permissions}
            connectedUser={connectedUser}
            getUsers={getUsers}
            getUserRole={getUserRole}
            role={role}
            isShowModal={isShowModal}
            handleCloseModal={closeModal}
            modalData={{
              type: "modal",
              modalId: "updateProfilModal",
              modalClass: "modal-xl",
            }}
          />
        )}
      </React.Fragment>
    ),
  };

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  return <React.Fragment>{admin_modals[actual_key]}</React.Fragment>;
};

export default AdminModalsSelector;
