import React from "react";
import "./../../styles/stats/progressBar.css";
import "./../../styles/stats/pourcent.css";

function ProgressBar({
  height = 5,
  width = 75,
  text = "",
  value = 100,
  color = "primary",
}) {
  const color_value =
    [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ].indexOf(color) != -1
      ? color
      : "primary";
  return (
    <>
      <div className="position-relative">
        <div
          className={
            "position-absolute mt-2 start-0 progress bsl-pb-" +
            height +
            " w-" +
            width
          }
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow={value}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className={"progress-bar bg-" + color_value + " w-p-" + value}
          ></div>
        </div>
        <span className="position-absolute end-0">{text}</span>
      </div>
    </>
  );
}

export default ProgressBar;
