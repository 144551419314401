import React, { useEffect, useState } from "react";
import Widget from "../stats/Widget";
import { FetchData } from "../../hooks/FetchData";
import "./../../styles/Tables/table.css";
import StatsFilterRound from "../stats/StatsFilterRound";
import BarChartCustom from "../stats/BarChartCustom";
import { useDispatch, useSelector } from "react-redux";
import { getEndDate } from "../../hooks/form/dateBSL";
import { initDateRange } from "../../store/dateRangeSlice";

const DashBoard = ({}) => {
  const dispatch = useDispatch();
  const [stats, setStats] = useState({});
  const [annee, setAnnee] = useState(new Date().getFullYear());
  const [activeChart, setActiveChart] = useState("clients");
  const [stats_data, setStats_data] = useState([]);
  const [clients, setClients] = [];
  const [order_statuts, setOrder_statuts] = useState([]);
  const [filtered, setFiltered] = useState({
    id: 0,
    label: "Tous les clients",
  });
  const [filterTitle, setFilterTitle] = useState("Tous les clients");

  useEffect(() => {
    if (activeChart === "clients") {
      setFiltered({ id: 0, label: "Tous les clients" });
      setFilterTitle("Tous les clients");
    } else {
      setFiltered({ id: 0, label: "Tous les sous-traitants" });
      setFilterTitle("Tous les sous-traitants");
    }
    getAllNeededData(activeChart);
    getStats([], annee, activeChart);
    getTotalsStats([], activeChart);
    changeChartMenu(activeChart);
  }, [annee, activeChart]);

  const getStats = async (idList = [], annee = "", activeChart = "clients") => {
    let url = "";
    let option = {};
    try {
      if (activeChart === "clients" || idList.length == 0) {
        url = "/api/order/stats";
        option = {
          method: "POST",
          body: JSON.stringify({ idUser: idList, annee: annee }),
        };
      } else {
        url = "/api/order/stats-order-st/";
        option = {
          method: "POST",
          body: JSON.stringify({ idUser: idList, annee: annee }),
        };
      }
      const { data } = await FetchData(url, option);
      if (data.status === 200) {
        setStats(data.stats);
        setStats((prev) => ({
          ...prev,
          orderByUser: data.statsUSerOrder.order_by_user,
        }));
      } else {
        console.error("Erreur de back-end : \n", data.error);
      }
    } catch (error) {
      console.error("erreur dans getStats : \n", error);
    }
  };

  const getTotalsStats = async (idList = [], activeChart = "clients") => {
    let url = "";
    let options = {};
    if (activeChart === "sous_traitants" && idList.length != 0) {
      url = "/api/order/total-stats-order-st/";
      options = {
        method: "POST",
        body: JSON.stringify({ idsToFilter: idList }),
      };
    } else {
      url = "/api/order/total-stats/";
      options = {
        method: "POST",
        body: JSON.stringify({ idsToFilter: idList }),
      };
    }
    try {
      const { data } = await FetchData(url, options);
      if (data.status === 200) {
        if (activeChart === "sous_traitants") {
          setStats_data([
            {
              text: "Nombre total commandes",
              value:
                idList.length != 0
                  ? data?.totalStats?.datastats?.total_order_st
                  : data?.totalStats?.datastats?.total_orders,
            },
          ]);
        } else {
          setStats_data([
            {
              text: "Nombre total de commandes",
              value: data?.totalStats?.datastats?.total_orders,
            },
            {
              text: "Chiffre d'affaires total",
              value: data?.totalStats?.datastats?.ca,
            },
            {
              text: "Marge totale",
              value: data?.totalStats?.datastats?.marge,
            },
            {
              text: "Coût total ",
              value: data?.totalStats?.datastats?.total_costs,
              icon_start: "fas fa-square-o",
            },
          ]);
        }
        return data;
      } else {
        console.error(data.error);
        return "error";
      }
    } catch (error) {
      console.error("erreur dans getTotalStats : ", error);
      return "error";
    }
  };

  // const [data, setData] = useState(stats_data[activeChart]);
  const [data_menu, setData_menu] = useState([
    {
      label: "Clients",
      key: "clients",
      selected: true,
      number: null,
    },
    {
      label: "Sous-traitants",
      key: "sous_traitants",
      selected: false,
      number: null,
    },
  ]);

  const [chartMenu, setChartMenu] = useState([]);

  const [activeChartFilter, setActiveChartFilter] = useState(clients);

  function clickFilter(key) {
    let new_data_menu = data_menu;
    new_data_menu = new_data_menu.map((d) => {
      if (d.key === key) {
        d.selected = true;
      } else {
        d.selected = false;
      }
      return d;
    });
    setData_menu(new_data_menu);
    setActiveChart(key);
  }

  function changeChartMenu(activeChart) {
    if (activeChart === "clients") {
      setChartMenu([
        {
          label: "Nombre de commandes",
          key: "nb_commande",
          selected: true,
          number: null,
        },
        {
          label: "Chiffres d'affaires",
          key: "ca",
          selected: false,
          number: null,
        },
        { label: "Coûts", key: "cout", selected: false, number: null },
        {
          label: "Commandes par utilisateur",
          key: "orderByUser",
          selected: false,
          number: null,
        },
      ]);
    } else {
      setChartMenu([
        {
          label: "Nombre de commandes",
          key: "nb_commande",
          selected: true,
          number: null,
        },
        {
          label: "Commandes par utilisateur",
          key: "orderByUser",
          selected: false,
          number: null,
        },
      ]);
    }
  }

  function updateTotalAndChart(option) {
    setFiltered(option);
    let idList = [];
    if (option.id > 0) {
      idList.push(option.id);
    }
    getTotalsStats(idList, activeChart);
    getStats(idList, annee, activeChart);
  }

  useEffect(() => {
    updateTotalAndChart(filtered);
  }, [filtered]);

  const getAllNeededData = async (activeChart) => {
    try {
      const { loading, data, errors } = await FetchData("/api/order/data-new", {
        method: "GET",
      });

      if (activeChart === "clients") {
        let oldChart = [{ id: 0, label: "Tous les clients" }];
        setActiveChartFilter([...oldChart, ...data.clients]);
      } else {
        let oldChart = [{ id: 0, label: "Tous les sous-traitants" }];
        setActiveChartFilter([...oldChart, ...data.subcontractors]);
      }

      // setCountryList(data.country);
      // setSubcontractors(data.subcontractors);
      // setOrders_subcontractors(data.data_subcontractor);
      setOrder_statuts(data.order_statuts);
      return { loading, data, errors };
    } catch (error) {
      return {};
    }
  };

  return (
    <>
      <div className={"row w-100 ps-0 pe-0 ms-0 me-0"}>
        {stats_data?.map((d, index) => (
          <div className={`col col-md-6 col-sm-12 pt-1 pe-0`} key={index}>
            <Widget data={d} />
          </div>
        ))}
      </div>
      <StatsFilterRound
        data_menu={data_menu}
        clickFilter={clickFilter}
        activeChartFilter={activeChartFilter}
        filtered={filtered}
        onChangeFilter={(e) => setFiltered(e)}
        filterTitle={filterTitle}
      />
      <BarChartCustom
        data_bar={stats}
        setAnnee={setAnnee}
        annee={annee}
        chartMenu={chartMenu}
      />
    </>
  );
};

export default DashBoard;
