const Menu = [
  {
    title: "Accueil",
    image: "img/mingcute_home-1-line.svg",
    selected: false,
    end: false,
  },
  {
    title: "Clients",
    image: "img/mingcute_building-5-line.svg",
    selected: true,
    end: true,
  },
  {
    title: "Sous traitants",
    image: "img/mingcute_group-line.svg",
    selected: false,
    end: false,
  },
  {
    title: "Demande",
    image: "img/mingcute_file-import-line.svg",
    selected: false,
    end: true,
  },
  {
    title: "Commande",
    image: "img/mingcute_file-check-line.svg",
    selected: false,
    end: false,
  },
  {
    title: "Admin",
    image: "img/mingcute_settings-3-line.svg",
    selected: false,
    end: true,
  },
].map((item, index) => ({ ...item, id: index }));

export { Menu };
