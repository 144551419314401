import React, { useCallback, useEffect, useState } from "react";
import Admin from "../root/Admin";
import TableTitle from "../Tables/TableTitle";
import Table from "../Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { initDropdown } from "../../store/searchDropdownSlice";
import { initFilter, setFilterOnly } from "../../store/table/filterTableSlice";
import { handle_filter } from "../../store/table/tableSlice";
import Widget from "../stats/Widget";
import { FetchData } from "../../hooks/FetchData";
import CommandeStats from "../stats/CommandeStats";
import AddCommande from "../Modals/Insert/commande/AddCommande";
import Cookies from "js-cookie";
import { initUpdate } from "../../store/table/btnIconSlice";
import UpdateCommande from "../Modals/Insert/commande/UpdateCommande";
import { getEndDate } from "../../hooks/form/dateBSL";
import { initDateRange } from "../../store/dateRangeSlice";
import ShowModalOrderinvoice from "./../Modals/Insert/commande/InvoiceCommande";
import { useAccess } from "../../hooks/useAccess";
import { RedirectUnallowed } from "../../hooks/RedirectUnallowed";
const CommandePage = () => {
  //Permissions
  const can_access_commande_page = useAccess(39);
  const can_create_order = useAccess(40);
  const can_see_order_infos = useAccess(42);
  const can_filter_order_by_clients = useAccess(78);
  const can_see_orders_by_subcontractors = useAccess(81);
  const can_see_orders_by_clients = useAccess(80);

  const userData = Cookies.get("userData");
  const idUser = JSON.parse(userData).id;
  const ref_name_page = "CommandePage";
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalUpdate, setIsShowModalUpdate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const data_action_td = useSelector((state) => state.btnIconSlice.data);

  const [clients, setClients] = useState([]);
  const [country_list, setCountryList] = useState([]);
  const [subcontractors, setSubcontractors] = useState([]);
  const [orders_subcontractors, setOrders_subcontractors] = useState([]);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [order_statuts, setOrder_statuts] = useState([]);

  const [logData, setLogData] = useState([]);
  const [firstChangeDate, setFirstChangeDate] = useState(0);

  const getLogOrder = async (orderId) => {
    const { loading, data, errors } = await FetchData(
      `/api/order/log/order/${orderId}`,
      {
        method: "GET",
      }
    );
    setLogData(data.data);
  };

  const date_range = useSelector((state) => state.dateRange.date_range);
  const actual_key = useSelector((state) => state.filterTable.actual_key);

  const dropdown_value_search = useSelector(
    (state) => state.dropdown.value_search[ref_name_page]
  );

  useEffect(() => {
    dispatch(
      handle_filter({
        dropdown_value_search: dropdown_value_search,
        colone_name_filter:
          actual_key === "client" ? "order_client_id" : "order_st_st_id",
      })
    );
  }, [dropdown_value_search]);

  useEffect(() => {
    setFirstChangeDate(firstChangeDate + 1);
  }, [date_range]);
  useEffect(() => {
    fetchDataBetweenDate();
  }, [firstChangeDate]);

  const getAllDataDefault = async () => {
    const { loading, data, errors } = await FetchData(`/api/order`, {
      method: "GET",
    });
    setOrders(data.data);
    setOrders_subcontractors(data.data_subcontractor);
  };

  const fetchDataBetweenDate = async () => {
    let date = getEndDate(-0).toISOString().split("T")[0];
    if (
      firstChangeDate > 1 &&
      (date_range.startDate !== date || date_range.endDate !== date)
    ) {
      const { loading, data, errors } = await FetchData(
        `/api/order/between-date/${date_range.startDate}/${date_range.endDate}`,
        {
          method: "GET",
        }
      );
      setOrders(data.data);
      setOrders_subcontractors(data.data_subcontractor);
    } else {
      getAllDataDefault();
    }
  };

  useEffect(() => {
    getAllNeededData();
    dispatch(
      initDateRange({
        startDate: getEndDate(-0).toISOString().split("T")[0], //new Date("2024-01-01").toISOString().split("T")[0],
        endDate: getEndDate(-0).toISOString().split("T")[0],
      })
    );
  }, []);

  const getAllNeededData = async () => {
    try {
      const { loading, data, errors } = await FetchData("/api/order/data-new", {
        method: "GET",
      });
      setClients(data.clients);
      setCountryList(data.country);
      setSubcontractors(data.subcontractors);
      // setOrders_subcontractors(data.data_subcontractor);
      setOrder_statuts(
        data.order_statuts
          .filter((old_statut) => old_statut.order_statut_id !== 7)
          .map((statut) => statut)
      );
      return { loading, data, errors };
    } catch (error) {
      return {};
    }
  };

  const modal_data = {
    type: "modal",
    modalId: "addCommandModal",
    modalClass: "modal-xl",
  };

  const getOrder = async (orderId) => {
    const { loading, data, errors } = await FetchData(`/api/order/${orderId}`, {
      method: "GET",
    });
    setOrder(data.data[0]);
    return data.data;
  };

  useEffect(() => {
    if (
      data_action_td.action_name === "update_order" &&
      data_action_td.action_value > 0
    ) {
      getOrder(data_action_td.action_value).then((e) => {
        setCommande();
        getLogOrder(data_action_td.action_value);
      });

      dispatch(
        initUpdate({
          action_value: -1,
        })
      );
    }
  }, [data_action_td.action_value]);

  const closeModal = useCallback(() => {
    setIsShowModal(false);
  }, []);
  const closeModalUpdate = useCallback(() => {
    setIsShowModalUpdate(false);
  }, []);

  useEffect(() => {
    dispatch(
      initFilter({
        actual_key: can_see_orders_by_clients ? "client" : "sous_traitants",
        filter_state: [
          {
            label: "Client",
            key: "client",
            selected: true,
            number: 0,
          },
          {
            label: "Sous traitants",
            key: "sous_traitants",
            selected: false,
            number: 0,
          },
        ],
        data_table: {
          client: {
            table_key: [
              "check",
              "date",
              "numero",
              "client_company_name",
              "devis",
              "statut",
              "order_origin_city",
              "order_dest_city",
              "order_goods_nb_colis",
              "order_goods_weight",
              "ref_envoi",
              "vente",
              "achat",
            ],
            table_headers: {
              check: { value: "checkbox", width: 20 },
              date: { value: "Date de validation", width: 130 },
              numero: { value: "N°commande", width: 100 },
              devis: { value: "Source", width: 90 },
              statut: { value: "Statut", width: 120 },
              order_origin_city: { value: "Origine", width: 150 },
              order_dest_city: { value: "Destination", width: 150 },
              client_company_name: { value: "Client", width: 150 },
              order_goods_nb_colis: { value: "Nb Colis", width: 100 },
              achat: { value: "Achat", width: 80 },
              order_goods_weight: { value: "Poids", width: 80 },
              vente: { value: "Vente", width: 80 },
              ref_envoi: {
                value: "Référence client",
                width: 150,
              },
            },
            data_table: orders,
            list_not_normal: ["devis", "statut"],
            object_serach_key: ["devis", "statut"],
            data_dropdown_list: clients,
            search_key: [
              "date",
              "numero",
              "client_company_name",
              "devis",
              "statut",
              "order_origin_city",
              "order_dest_city",
              "order_goods_nb_colis",
              "order_goods_weight",
              "ref_envoi",
              "vente",
              "achat",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          sous_traitants: {
            table_key: [
              "date",
              "st_company_name",
              "statut",
              "order_origin_city",
              "order_dest_city",
              "ref_envoi",
              "order_goods_num_track_expedition",
              "order_st_cost",
            ],
            table_headers: {
              date: { value: "Date de validation", width: 130 },
              st_company_name: { value: "Sous traitant", width: 150 },
              statut: { value: "Statut", width: 120 },
              order_origin_city: { value: "Origine", width: 150 },
              order_dest_city: { value: "Destination", width: 150 },
              ref_envoi: { value: "Référence client", width: 150 },
              order_goods_num_track_expedition: { value: "N°LTA", width: 150 },
              order_st_cost: { value: "Coût", width: 100 },
            },
            data_table: orders_subcontractors,
            list_not_normal: ["statut"],
            object_serach_key: ["statut"],
            data_dropdown_list: subcontractors,
            search_key: [
              "date",
              "st_company_name",
              "statut",
              "order_origin_city",
              "order_dest_city",
              "ref_envoi",
              "order_goods_num_track_expedition",
              "order_st_cost",
            ],
            data_label: {
              title: "Tous les sous traitants",
              select_all: "Tous les sous traitants",
            },
          },
        },
      })
    );
  }, [orders, orders_subcontractors]);

  useEffect(() => {
    dispatch(
      initDropdown({
        reference_name: ref_name_page,
        value_search: { CommandePage: ["All"] },
        data_label: can_filter_order_by_clients
          ? {
              title: "Tous les clients",
              select_all: "Tous les clients",
            }
          : {
              title: "Tous les sous-traitants",
              select_all: "Tous sous-traitants",
            },
      })
    );
  }, []);

  const NewCommande = useCallback(() => {
    setIsShowModal(true);
    setOrder(null);
  }, []);

  const setCommande = useCallback(() => {
    setIsUpdate(false);
    setIsShowModalUpdate(can_see_order_infos);
  }, []);

  return can_access_commande_page ? (
    <Admin>
      <AddCommande
        modalData={modal_data}
        isShowModal={isShowModal}
        handleCloseModal={closeModal}
        data_clients={clients}
        data_country_list={country_list}
        data_subcontractors={subcontractors}
        order={order}
        changeOrder={setOrder}
        idUser={idUser}
        getAllOrders={fetchDataBetweenDate}
        getAllNeededData={getAllNeededData}
      />
      <UpdateCommande
        modalData={modal_data}
        isShowModal={isShowModalUpdate}
        handleCloseModal={closeModalUpdate}
        data_clients={clients}
        data_country_list={country_list}
        data_subcontractors={subcontractors}
        order={order}
        changeOrder={setOrder}
        idUser={idUser}
        getAllOrders={fetchDataBetweenDate}
        getAllNeededData={getAllNeededData}
        isUpdate={isUpdate}
        changeIsUpdate={setIsUpdate}
        order_statuts={order_statuts}
        data_log={logData}
      />
      <TableTitle
        title="Commandes"
        button_name="Commande Directe"
        show_button={can_create_order}
        modalId={modal_data?.modalId}
        onClick={NewCommande}
      />
      <InitCommande fetchDataBetweenDate={fetchDataBetweenDate} />
      {isShowModal == true || isShowModalUpdate == true ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}
    </Admin>
  ) : (
    RedirectUnallowed("/Accueil")
  );
};

function InitCommande({ fetchDataBetweenDate }) {
  //Permissions
  const can_export_order = useAccess(41);
  const can_search_order = useAccess(75);
  const can_send_order_to_invoice = useAccess(76);
  const can_filter_order_by_validation_date = useAccess(77);
  const can_filter_order_by_clients = useAccess(78);
  const can_filter_order_by_subcontractors = useAccess(79);
  const can_see_orders_by_clients = useAccess(80);
  const can_see_orders_by_subcontractors = useAccess(81);

  const [invoice, setInvoice] = useState([]);
  const dispatch = useDispatch();
  const ref_name_page = "CommandePage";
  const actual_key = useSelector((state) => state.filterTable.actual_key);
  const data_table = useSelector((state) => state.filterTable.data_table);
  const value_search = useSelector((state) => state.dropdown.value_search);

  useEffect(() => {
    dispatch(
      initDropdown({
        reference_name: ref_name_page,
        value_search: { CommandePage: ["All"] },
        data_label:
          actual_key === "client" && can_filter_order_by_clients
            ? { title: "Tous les clients", select_all: "Tous les clients" }
            : {
                title: "Tous les sous-traitants",
                select_all: "Tous les sous-traitants",
              },
      })
    );
  }, [actual_key]);

  const data_store = useSelector((state) => state.table.data);
  const getLength = (cle) => {
    return actual_key == cle
      ? data_store.length
      : data_table[cle]?.data_table?.length;
  };

  useEffect(() => {
    dispatch(
      setFilterOnly([
        can_see_orders_by_clients
          ? {
              label: "Client",
              key: "client",
              selected: actual_key == "client",
              number: getLength("client"),
            }
          : [],
        can_see_orders_by_subcontractors
          ? {
              label: "Sous traitants",
              key: "sous_traitants",
              selected: actual_key == "sous_traitants",
              number: getLength("sous_traitants"),
            }
          : [],
      ])
    );
  }, [actual_key, data_store]);

  /*Pour l"envoi de facture vers dolibarr */

  const [isShowModale, setIsShowModale] = useState(false);

  const getInvoiceAvilable = async (data_option) => {
    if (data_option.length > 0) {
      const { loading, data, errors } = await FetchData(
        `/api/order/invoice/available`,
        {
          method: "POST",
          body: JSON.stringify({ order_id: data_option }),
        }
      );
      if (!errors) {
        setInvoice(data);
      }
    } else {
      setInvoice([]);
    }
    return [];
  };
  const all_table_data = useSelector((state) => state.table.data);
  useEffect(() => {
    let data_ = all_table_data
      .filter((d) => d.check === true)
      .map((d) => d.order_id);
    getInvoiceAvilable(data_);
  }, [all_table_data]);

  const onClickSendInvoice = () => {
    setIsShowModale((e) => !e);
  };
  return (
    <>
      {Object.keys(data_table).length !== 0 &&
        (can_see_orders_by_clients || can_see_orders_by_subcontractors) && (
          <>
            <Table
              data_table={data_table[actual_key]?.data_table}
              table_headers={data_table[actual_key].table_headers}
              table_key={data_table[actual_key].table_key}
              search_key={data_table[actual_key].search_key}
              list_not_normal={data_table[actual_key].list_not_normal}
              object_serach_key={data_table[actual_key].object_serach_key}
              show_client_filter={
                actual_key == "client"
                  ? can_filter_order_by_clients
                  : actual_key == "sous_traitants"
                  ? can_filter_order_by_subcontractors
                  : true
              }
              show_date_filter={can_filter_order_by_validation_date}
              data_dropdown_list={data_table[actual_key].data_dropdown_list}
              ref_name_page={ref_name_page}
              goUrl={true}
              filter_ref={null}
              data_btn_control={{
                show_export_btn: can_export_order,
                show_delete_btn: false,
                show_change_status_btn: false,
                infosInvoice: {
                  show_invoice_btn: can_send_order_to_invoice,
                  count_order_invoice: invoice?.data?.length,
                },
              }}
              onSendInvoice={onClickSendInvoice}
              show_search_input={can_search_order}
            />
            <ShowModalOrderinvoice
              invoice_data={invoice}
              isShowModale={isShowModale}
              setIsShowModale={setIsShowModale}
              setInvoice={setInvoice}
              refreshData={fetchDataBetweenDate}
            />
          </>
        )}
    </>
  );
}

export default CommandePage;