import React, { useCallback, useEffect, useState } from "react";
import Admin from "../root/Admin";
import TableTitle from "../Tables/TableTitle";
import Table from "../Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { initDropdown } from "../../store/searchDropdownSlice";
import { initFilter, setFilterOnly } from "../../store/table/filterTableSlice";
import { initUpdate } from "../../store/table/btnIconSlice";
import { handle_filter } from "../../store/table/tableSlice";
import AddDevis from "../Modals/Insert/demande/AddDevis";
import UpdateDevis from "../Modals/Insert/demande/UpdateDevis";
import Swal from "sweetalert2";
import { FetchData } from "../../hooks/FetchData";
import Cookies from "js-cookie";
import { getEndDate } from "../../hooks/form/dateBSL";
import { initDateRange } from "../../store/dateRangeSlice";
import { useAccess } from "../../hooks/useAccess";
import { RedirectUnallowed } from "../../hooks/RedirectUnallowed";

const DemandePage = () => {
  //Permissions
  const can_access_demande_page = useAccess(24);
  const can_see_all_request = useAccess(28);
  const can_see_open_request = useAccess(29);
  const can_see_in_progress_request = useAccess(30);
  const can_see_answered_request = useAccess(31);
  const can_see_validated_request = useAccess(32);
  const can_see_aborted_request = useAccess(33);
  const can_see_refused_request = useAccess(34);
  const can_create_new_request = useAccess(35);
  const can_see_open_request_infos = useAccess(36);
  const can_see_in_progress_request_infos = useAccess(61);
  const can_see_answered_request_infos = useAccess(64);

  const userData = Cookies.get("userData");
  const idUser = JSON.parse(userData).id;
  const ref_name_page = "DemandePage";
  const dispatch = useDispatch();

  const data_action_td = useSelector((state) => state.btnIconSlice.data);

  const dropdown_value_search = useSelector(
    (state) => state.dropdown.value_search[ref_name_page]
  );
  const [demande, setDemande] = useState(null);
  const [relance, setRelance] = useState(null);
  const [sous_traitans, set_sous_traitans] = useState(null);
  const [answer, set_answer] = useState(null);
  const [all_action, set_all_action] = useState([]);
  const [all_client, set_all_client] = useState([]);
  const [all_canal, set_all_canal] = useState([]);
  const [action, set_action] = useState([]);
  const [dataFetch, setDataFetch] = useState([]);

  const [needsData, setNeedData] = useState(null);

  const [country_list, setCountryList] = useState([]);

  const [logData, setLogData] = useState([]);
  const [firstChangeDate, setFirstChangeDate] = useState(0);

  useEffect(() => {
    dispatch(
      handle_filter({
        dropdown_value_search: dropdown_value_search,
        colone_name_filter: "req_client_id",
      })
    );
  }, [dropdown_value_search]);

  const getDataNeedes = async () => {
    const { loading, data, errors } = await FetchData("/api/request/data-new", {
      method: "GET",
    });
    setNeedData(data.data);
    set_all_client(data.data.client_list);
    setCountryList(data.data.country);
    set_sous_traitans(data.data.sous_traitants);
    set_all_action(data.data.all_action);
    return data.data;
  };
  const [status, setStatus] = useState([]);
  const date_range = useSelector((state) => state.dateRange.date_range);

  useEffect(() => {
    setFirstChangeDate(firstChangeDate + 1);
  }, [date_range]);
  useEffect(() => {
    fetchDataBetweenDate();
  }, [firstChangeDate]);

  const getAllDataDefault = async () => {
    const { loading, data, errors } = await FetchData(`/api/request`, {
      method: "GET",
    });
    setStatus(data.status);
    setDataFetch(data.data);
  };

  useEffect(() => {
    dispatch(
      initFilter({
        actual_key: getActualKeyByAccess(
          can_see_all_request,
          can_see_open_request,
          can_see_in_progress_request,
          can_see_answered_request,
          can_see_validated_request,
          can_see_aborted_request,
          can_see_refused_request
        ),
        filter_state: [],
        data_table: {
          tous: {
            table_key: [
              "req_date_request",
              "req_time_request",
              "numero",
              "client_company_name",
              "statut",
              "order_num_title",
              "req_origin_city",
              "req_dest_city",
              "req_as_sell_price",
              "req_as_buying_price",
              "resp",
              "action",
            ],
            table_headers: {
              req_date_request: { value: "Date", width: 90 },
              req_time_request: { value: "Heure", width: 80 },
              numero: { value: "N° Demande", width: 110 },
              client_company_name: { value: "Client", width: 100 },
              statut: { value: "Statut", width: 90 },
              order_num_title: { value: "N°commande", width: 110 },
              req_origin_city: { value: "Origine", width: 150 },
              req_dest_city: { value: "Destination", width: 150 },
              req_as_sell_price: { value: "Prix vente", width: 80 },
              req_as_buying_price: { value: "Prix achat", width: 80 },
              resp: { value: "Resp", width: 150 },
              action: { value: "Action", width: 150 },
            },
            data_table: dataFetch,
            list_not_normal: ["statut", "resp"],
            object_serach_key: ["statut", "resp"],
            data_dropdown_list: all_client.map((item) => ({
              id: item.id,
              label: item.label,
            })),
            search_key: [
              "req_date_request",
              "req_time_request",
              "numero",
              "order_num_title",
              "client_company_name",
              "statut",
              "req_origin_city",
              "req_dest_city",
              "req_as_sell_price",
              "req_as_buying_price",
              "resp",
              "action",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          7: {
            table_key: [
              "req_date_request",
              "req_time_request",
              "numero",
              "client_company_name",
              "req_origin_city",
              "req_dest_city",
            ],
            table_headers: {
              req_date_request: { value: "Date", width: 90 },
              req_time_request: { value: "Heure", width: 80 },
              numero: { value: "N° Demande", width: 90 },
              client_company_name: { value: "Client", width: 100 },
              statut: { value: "Statut", width: 90 },
              req_origin_city: { value: "Origine", width: 150 },
              req_dest_city: { value: "Destination", width: 150 },
              req_as_sell_price: { value: "Prix vente", width: 80 },
              req_as_buying_price: { value: "Prix achat", width: 80 },
              resp: { value: "Resp", width: 150 },
              action: { value: "Action", width: 150 },
            },
            data_table: dataFetch.filter((d) => d.req_id_status == 7),
            list_not_normal: [],
            object_serach_key: [],
            data_dropdown_list: all_client.map((d) => d),
            search_key: [
              "req_date_request",
              "client_company_name",
              "req_time_request",
              "numero",
              "req_origin_city",
              "req_dest_city",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          1: {
            table_key: [
              "req_date_request",
              "req_time_request",
              "numero",
              "client_company_name",
              "req_origin_city",
              "req_dest_city",
              "resp",
            ],
            table_headers: {
              req_date_request: { value: "Date", width: 90 },
              req_time_request: { value: "Heure", width: 80 },
              numero: { value: "N° Demande", width: 90 },
              req_origin_city: { value: "Origine", width: 150 },
              req_dest_city: { value: "Destination", width: 150 },
              client_company_name: { value: "Client", width: 100 },
              resp: { value: "Resp", width: 150 },
            },
            data_table: dataFetch.filter((d) => d.req_id_status == 1),
            list_not_normal: ["resp"],
            object_serach_key: ["resp"],
            data_dropdown_list: all_client,
            search_key: [
              "req_date_request",
              "client_company_name",
              "req_time_request",
              "numero",
              "req_origin_city",
              "req_dest_city",
              "resp",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          2: {
            table_key: [
              // "req_as_date_answer",
              // "req_as_time_answer",
              "numero",
              "client_company_name",
              "req_origin_city",
              "req_dest_city",
              "req_as_sell_price",
              "req_as_buying_price",
              "relance",
              "rr_created_at",
              "resp",
            ],
            table_headers: {
              // req_as_date_answer: { value: "Date", width: 80 },
              // req_as_time_answer: { value: "Heure", width: 80 },
              numero: { value: "N° Demande", width: 90 },
              client_company_name: { value: "Client", width: 100 },
              req_origin_city: { value: "Origine", width: 150 },
              req_dest_city: { value: "Destination", width: 150 },
              req_as_sell_price: { value: "Prix vente", width: 80 },
              req_as_buying_price: { value: "Prix achat", width: 80 },
              resp: { value: "Resp", width: 150 },
              action: { value: "Action", width: 150 },
              relance: { value: "Relance", width: 90 },
              rr_created_at: { value: "Der. Relance", width: 100 },
            },
            data_table: dataFetch.filter((d) => d.req_id_status == 2),
            list_not_normal: ["resp"],
            object_serach_key: ["resp"],
            data_dropdown_list: all_client,
            search_key: [
              // "req_as_date_answer",
              "client_company_name",
              // "req_as_time_answer",
              "numero",
              "req_origin_city",
              "req_dest_city",
              "req_as_sell_price",
              "req_as_buying_price",
              "relance",
              "rr_created_at",
              "resp",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          4: {
            table_key: [
              "req_date_request",
              "numero",
              "client_company_name",
              "ri_comment",
              "resp",
            ],
            table_headers: {
              req_date_request: { value: "Date", width: 90 },
              numero: { value: "N° Demande", width: 90 },
              client_company_name: { value: "Client", width: 100 },
              ri_comment: { value: "Raison annulation", width: 200 },
              resp: { value: "Resp", width: 150 },
            },
            data_table: dataFetch.filter((d) => d.req_id_status === 4),
            list_not_normal: ["resp"],
            object_serach_key: ["resp"],
            data_dropdown_list: all_client,
            search_key: [
              "date",
              "req_date_request",
              "client_company_name",
              "numero",
              "ri_comment",
              "destination",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          5: {
            table_key: [
              "req_date_request",
              "numero",
              "client_company_name",
              "ri_comment",
              "resp",
            ],
            table_headers: {
              req_date_request: { value: "Date", width: 90 },
              numero: { value: "N° Demande", width: 90 },
              client_company_name: { value: "Client", width: 100 },
              ri_comment: { value: "Raison annulation", width: 200 },
              resp: { value: "Resp", width: 150 },
            },
            data_table: dataFetch.filter((d) => d.req_id_status === 5),
            list_not_normal: ["resp"],
            object_serach_key: ["resp"],
            data_dropdown_list: all_client,
            search_key: [
              "date",
              "req_date_request",
              "client_company_name",
              "numero",
              "ri_comment",
              "destination",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
          3: {
            table_key: [
              "req_date_request",
              "numero",
              "order_num_title",
              "client_company_name",
              "req_origin_city",
              "req_dest_city",
              "action",
              "resp",
            ],
            table_headers: {
              req_date_request: { value: "Date", width: 90 },
              req_time_request: { value: "Heure", width: 80 },
              numero: { value: "N° Demande", width: 100 },
              order_num_title: { value: "N°commande", width: 110 },
              client_company_name: { value: "Client", width: 100 },
              req_id_status: { value: "Statut", width: 90 },
              req_origin_city: { value: "Origine", width: 150 },
              req_dest_city: { value: "Destination", width: 150 },
              resp: { value: "Resp", width: 150 },
              action: { value: "Action", width: 150 },
            },
            data_table: dataFetch.filter((d) => d.req_id_status == 3),
            list_not_normal: ["action", "resp"],
            object_serach_key: ["resp"],
            data_dropdown_list: all_client,
            search_key: [
              "req_date_request",
              "numero",
              "order_num_title",
              "client_company_name",
              "req_origin_city",
              "req_dest_city",
              "resp",
            ],
            data_label: {
              title: "Tous les clients",
              select_all: "Tous les clients",
            },
          },
        },
      })
    );
    dispatch(
      initDropdown({
        reference_name: ref_name_page,
        value_search: { DemandePage: ["All"] },
        data_label: {
          title: "Tous les clients",
          select_all: "Tous les clients",
        },
      })
    );
  }, [dataFetch]);

  const fetchDataBetweenDate = async () => {
    let date = getEndDate(-0).toISOString().split("T")[0];
    if (
      firstChangeDate > 1 &&
      (date_range.startDate !== date || date_range.endDate !== date)
    ) {
      const { loading, data, errors } = await FetchData(
        `/api/request/between-date/${date_range.startDate}/${date_range.endDate}`,
        {
          method: "GET",
        }
      );
      setStatus(data.status);
      setDataFetch(data.data);
    } else {
      getAllDataDefault();
    }
  };

  const getDemande = async (demandeId) => {
    const { loading, data, errors } = await FetchData(
      `/api/request/${demandeId}`,
      {
        method: "GET",
      }
    );
    setDemande(data.data);
    setRelance(data.reminder);
    set_answer(data.answer);
    set_action(data.action);
    set_all_canal(data.reminder_canal);
    return data.data;
  };

  const getLogRequest = async (demandeId) => {
    const { loading, data, errors } = await FetchData(
      `/api/request/log/request/${demandeId}`,
      {
        method: "GET",
      }
    );
    setLogData(data.data);
  };

  function getActualKeyByAccess(
    can_see_all = true,
    can_see_open = true,
    can_see_in_progress = true,
    can_see_answered = true,
    can_see_validated = true,
    can_see_aborted = true,
    can_see_refused = true
  ) {
    let actual_key = "tous";
    if (!can_see_all) {
      actual_key = "7";
      if (!can_see_open) {
        actual_key = "1";
        if (!can_see_in_progress) {
          actual_key = "2";
          if (!can_see_answered) {
            actual_key = "3";
            if (!can_see_validated) {
              actual_key = "4";
              if (!can_see_aborted) {
                actual_key = "5";
                if (!can_see_refused) {
                  actual_key = "tous";
                }
              }
            }
          }
        }
      }
    }
    return actual_key;
  }

  useEffect(() => {
    getDataNeedes();
    dispatch(
      initDateRange({
        startDate: getEndDate(-0).toISOString().split("T")[0], //new Date("2024-01-01").toISOString().split("T")[0],
        endDate: getEndDate(-0).toISOString().split("T")[0],
      })
    );
  }, []);

  useEffect(() => {
    if (
      data_action_td.action_name === "update_demande" &&
      data_action_td.action_value > 0
    ) {
      getDemande(data_action_td.action_value).then((e) => {
        e.req_id_status === 7
          ? showModal(e.req_id_status)
          : showModalUpdate(e.req_id_status);

        getLogRequest(data_action_td.action_value);
      });

      dispatch(
        initUpdate({
          action_value: -1,
        })
      );
    }
  }, [data_action_td.action_value]);

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalUpdate, setIsShowModalUpdate] = useState(false);

  const modal_data = {
    type: "modal",
    modalId: "addCollaboratorModal",
    modalClass: "modal-xl",
  };

  const modal_data_update = {
    type: "modal",
    modalId: "updateDemande",
    modalClass: "modal-xl",
  };

  const closeModal = useCallback(() => {
    setIsShowModal(false);
    setIsShowModalUpdate(false);
    setDemande(null);
  }, []);

  const showModal = useCallback((req_id_statut) => {
    switch (req_id_statut) {
      case 7: {
        setIsShowModal(can_see_open_request_infos);
        break;
      }
      default:
        setIsShowModal(true);
    }
  }, []);

  const showModalUpdate = useCallback((req_id_statut) => {
    switch (req_id_statut) {
      case 1: {
        setIsShowModalUpdate(can_see_in_progress_request_infos);
        break;
      }
      case 2: {
        setIsShowModalUpdate(can_see_answered_request_infos);
        break;
      }
      default:
        setIsShowModalUpdate(true);
    }
  }, []);

  const NewCommande = useCallback(() => {
    setIsShowModal(true);
    setDemande(null);
  }, []);

  return can_access_demande_page ? (
    <Admin>
      <AddDevis
        demande={demande}
        getData={fetchDataBetweenDate}
        modalData={modal_data}
        isShowModal={isShowModal}
        handleCloseModal={closeModal}
        data_list={needsData}
        idUser={idUser}
        data_country_list={country_list}
        data_log={logData}
      />
      <UpdateDevis
        relance={relance}
        answer={answer}
        idUser={idUser}
        demande={demande}
        getData={fetchDataBetweenDate}
        modalData={modal_data_update}
        isShowModal={isShowModalUpdate}
        handleCloseModal={closeModal}
        sous_traitans={sous_traitans}
        data_list={needsData}
        getDemande={getDemande}
        all_action={all_action}
        action={action}
        get_all_action={getDataNeedes}
        all_canal={all_canal}
        data_country_list={country_list}
        data_log={logData}
      />
      <TableTitle
        title="Demande"
        button_name="Nouvelle demande"
        show_button={can_create_new_request}
        modalId={modal_data?.modalId}
        onClick={NewCommande}
      />
      {(can_see_all_request ||
        can_see_open_request ||
        can_see_in_progress_request ||
        can_see_answered_request ||
        can_see_aborted_request ||
        can_see_refused_request ||
        can_see_validated_request) && (
        <InitDevis idUser={idUser} status={status} />
      )}

      {isShowModal == true || isShowModalUpdate == true ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}
    </Admin>
  ) : (
    RedirectUnallowed("/Accueil")
  );
};

function InitDevis({ idUser, status }) {
  //Permissions
  const can_search_request = useAccess(25);
  const can_filter_request_by_client = useAccess(26);
  const can_filter_request_by_date = useAccess(27);
  const can_see_all_request = useAccess(28);
  const can_see_open_request = useAccess(29);
  const can_see_in_progress_request = useAccess(30);
  const can_see_answered_request = useAccess(31);
  const can_see_validated_request = useAccess(32);
  const can_see_aborted_request = useAccess(33);
  const can_see_refused_request = useAccess(34);
  const can_export_requests = useAccess(38);

  const dispatch = useDispatch();
  const ref_name_page = "DemandePage";
  const actual_key = useSelector((state) => state.filterTable.actual_key);
  const data_table = useSelector((state) => state.filterTable.data_table);
  const data_store = useSelector((state) => state.table.data);

  const getLength = (cle) => {
    return actual_key == cle
      ? data_store.length
      : data_table[cle]?.data_table?.length;
  };

  useEffect(() => {
    const can_see_all_data = can_see_all_request
      ? [
          {
            label: "Tous",
            key: "tous",
            selected: actual_key == "tous",
            number: getLength("tous"),
          },
        ]
      : [];
    const can_see_open_data = can_see_open_request
      ? [
          ...status
            .filter((d) => d.request_status_id === 7)
            .map((d, i) => {
              return {
                label: d.request_status_name,
                key: d.request_status_id,
                selected: actual_key == d.request_status_id,
                number: getLength(d.request_status_id),
              };
            }),
        ]
      : [];
    const can_see_in_progress_data = can_see_in_progress_request
      ? [
          ...status
            .filter((d) => d.request_status_id === 1)
            .map((d, i) => {
              return {
                label: d.request_status_name,
                key: d.request_status_id,
                selected: actual_key == d.request_status_id,
                number: getLength(d.request_status_id),
              };
            }),
        ]
      : [];

    const can_see_answered_data = can_see_answered_request
      ? [
          ...status
            .filter((d) => d.request_status_id === 2)
            .map((d, i) => {
              return {
                label: d.request_status_name,
                key: d.request_status_id,
                selected: actual_key == d.request_status_id,
                number: getLength(d.request_status_id),
              };
            }),
        ]
      : [];

    const can_see_validated_data = can_see_validated_request
      ? [
          ...status
            .filter((d) => d.request_status_id === 3)
            .map((d, i) => {
              return {
                label: d.request_status_name,
                key: d.request_status_id,
                selected: actual_key == d.request_status_id,
                number: getLength(d.request_status_id),
              };
            }),
        ]
      : [];

    const can_see_aborted_data = can_see_aborted_request
      ? [
          ...status
            .filter((d) => d.request_status_id === 4)
            .map((d, i) => {
              return {
                label: d.request_status_name,
                key: d.request_status_id,
                selected: actual_key == d.request_status_id,
                number: getLength(d.request_status_id),
              };
            }),
        ]
      : [];

    const can_see_refused_data = can_see_refused_request
      ? [
          ...status
            .filter((d) => d.request_status_id === 5)
            .map((d, i) => {
              return {
                label: d.request_status_name,
                key: d.request_status_id,
                selected: actual_key == d.request_status_id,
                number: getLength(d.request_status_id),
              };
            }),
        ]
      : [];

    const all_status = [
      ...can_see_all_data,
      ...can_see_open_data,
      ...can_see_in_progress_data,
      ...can_see_answered_data,
      ...can_see_validated_data,
      ...can_see_aborted_data,
      ...can_see_refused_data,
    ];
    dispatch(setFilterOnly(all_status));
  }, [actual_key, data_store, status]);

  useEffect(() => {
    dispatch(
      initDropdown({
        reference_name: ref_name_page,
        value_search: { DemandePage: ["All"] },
        data_label: data_table[actual_key]?.data_label,
      })
    );
  }, [actual_key]);

  const data_btn_control = {
    show_export_btn: can_export_requests,
    show_delete_btn: false,
    show_change_status_btn: false,
  };

  const delete_request = async (id) => {
    const { loading, data, errors } = await FetchData(
      `/api/request/delete/${id}`,
      {
        method: "PUT",
        body: JSON.stringify({ req_user_id_update: idUser }),
      }
    );
    return data;
  };

  const handleDeletes = () => {
    //recuperation des utilisateur a supprimer
    const demandeIds = data_store
      .filter((demande) => demande.check === true)
      .map((demande) => demande.req_id);

    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Voulez vous vraiment supprimer ces demandes ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed && demandeIds) {
        delete_request(demandeIds).then((result) => {
          if (result?.status === 200) {
            Swal.fire({
              title: "Supprimé!",
              text: "Les demandes ont été supprimé.",
              icon: "success",
              position: "center",
              showConfirmButton: false,
              timer: 3000,
            });
            // fetchData();
          } else {
            Swal.fire({
              title: "Erreur",
              text: result?.error,
              icon: "error",
              confirmButtonColor: "#0e3feb",
              confirmButtonText: "OK",
            });
          }
        });
      }
    });
  };

  return (
    <>
      {Object.keys(data_table).length !== 0 && (
        <Table
          data_table={data_table[actual_key]?.data_table}
          table_headers={data_table[actual_key].table_headers}
          table_key={data_table[actual_key].table_key}
          search_key={data_table[actual_key].search_key}
          list_not_normal={data_table[actual_key].list_not_normal}
          object_serach_key={data_table[actual_key].object_serach_key}
          show_client_filter={can_filter_request_by_client}
          show_date_filter={can_filter_request_by_date}
          data_dropdown_list={data_table[actual_key].data_dropdown_list}
          data_btn_control={data_btn_control}
          ref_name_page={ref_name_page}
          goUrl={true}
          filter_ref={null}
          onDelete={handleDeletes}
          show_search_input={can_search_request}
        />
      )}
    </>
  );
}

export default DemandePage;
