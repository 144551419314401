import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./../../styles/Tables/footer.css";
import { useSelector } from "react-redux";

function TableFooter({
  currentPage,
  setCurrentPage,
  totalItems,
  itemsPerPage,
  marginTop,
}) {
  const search_value = useSelector((state) => state.table.search_value);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    if (search_value.length > 0) {
      setCurrentPage(0);
    }
  }, [search_value]);

  return (
    <div
      className="p-3 bg-lighter footerContainer"
      style={{
        marginTop: marginTop,
      }}
    >
      <div className="pagination-container float-right">
        <ReactPaginate
          previousLabel={<i className="fas fa-angle-left"></i>}
          nextLabel={<i className="fas fa-angle-right"></i>}
          pageCount={Math.ceil(totalItems / itemsPerPage)}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          forcePage={currentPage}
          previousClassName={currentPage === 0 ? "bsl-pagin-disabled " : ""}
          nextClassName={
            currentPage === Math.ceil(totalItems / itemsPerPage) - 1
              ? "bsl-pagin-disabled "
              : ""
          }
          disabledClassName={"bsl-pagin-disabled "}
        />
      </div>
      <div className="items-per-page-container float-left"></div>
    </div>
  );
}

export default TableFooter;
