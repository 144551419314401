import React from "react";
import Admin from "../root/Admin";
import TableTitle from "../Tables/TableTitle";
import DashBoard from "../stats/Dashboard";
import { useAccess } from "../../hooks/useAccess.js";
import { RedirectUnallowed } from "../../hooks/RedirectUnallowed.js";

const HomePage = () => {
  return (
    <Admin>
      <TableTitle title="Accueil" />
      <DashBoard />
    </Admin>
  );
};

export default HomePage;
