import { Collapse } from "bootstrap";
import React from "react";
import { useUser } from "../../UserContext";
/**
 * Profile : name and post desciption
 *
 * @param {{name:String, job: String, avatar:String, collapse: boolean}} props
 * @returns component
 * @author Johns David
 */
function Profile({ collapse = false }) {
  const { user } = useUser();
  const getInitials = () => {
    const firstNameInitial = user?.fname?.charAt(0).toUpperCase();
    const lastNameInitial = user?.lname?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial}`;
  };
  return (
    <div className={"profil " + (collapse && "profil-collapse")}>
      <div className="frame-2">
        <div className="avatar">
          <div className="s">{getInitials()}</div>
        </div>
        {!collapse && (
          <div className="frame-3">
            <div className="text-wrapper-2">
              {user?.fname + " " + user?.lname}
            </div>
            <div className="text-wrapper-3">{user?.user_role_name}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
