import React, { useEffect, useState } from "react";

const NavsAndTabs = ({ data_tab = [] }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    setActiveTab(1);
  }, [data_tab]);

  return (
    <>
      <nav className="d-flex position-relative nav-gray">
        <div
          className="nav nav-tabs w-100"
          id="nav-tab"
          role="tablist"
          style={{ border: "none" }}
        >
          {data_tab?.map((tab) => (
            <button
              key={tab.id}
              className={`nav-link text-center flex-grow-1 ${
                activeTab === tab.id ? "active" : ""
              }`}
              id={`nav-${tab.name.toLowerCase()}-tab`}
              onClick={() => handleTabClick(tab.id)}
              type="button"
              role="tab"
              aria-controls={`nav-${tab.name.toLowerCase()}`}
              aria-selected={activeTab === tab.id ? "true" : "false"}
            >
              <span className="text-muted" style={{ fontSize: "15px" }}>
                {tab.name}
                {tab?.show_notif && (
                  <i className="fa-solid fa-circle-exclamation text-danger ms-2"></i>
                )}
              </span>
            </button>
          ))}
        </div>
      </nav>
      <div className="modal-body p-0" style={{ minHeight: "80%" }}>
        {" "}
        <div className="tab-content" id="nav-tabContent">
          {data_tab.map((tab) => (
            <div
              key={tab.id}
              className={`tab-pane fade ${
                activeTab === tab.id ? "show active" : ""
              }`}
              id={`nav-${tab.name.toLowerCase()}`}
              role="tabpanel"
              aria-labelledby={`nav-${tab.name.toLowerCase()}-tab`}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NavsAndTabs;
