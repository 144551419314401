import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { useToggle } from "react-use";
import useValidateDate from "../../hooks/form/useValidateDate";
import { useDispatch, useSelector } from "react-redux";
import { initDateRange } from "../../store/dateRangeSlice";

function DateRangeFilter() {
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const dispatch = useDispatch();
  const date_range = useSelector((state) => state.dateRange.date_range);
  const {
    label,
    dateRange,
    state,
    toggle,
    setToggle,
    handleValid,
    setState,
    clickShowDropdown,
  } = useValidateDate(
    new Date(date_range.startDate),
    new Date(date_range.endDate)
  );

  const vaidated = () => {
    const data = {
      startDate: formatDateToYYYYMMDD(state.startDate),
      endDate: formatDateToYYYYMMDD(state.endDate),
    };
    dispatch(initDateRange(data));
    handleValid();
  };
  return (
    <>
      <div className="dropdown bsl-search-drop">
        <button
          type="button"
          className="alert alert-secondary dropdown-toggle p-1 mb-0"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
          onClick={clickShowDropdown}
        >
          <i className="fa-regular fa-calendar me-2 fs-5"></i>
          {label}
        </button>
        <ul
          className={`dropdown-menu px-2 bsl-dropdown-serach-content" ${
            toggle ? "show" : ""
          }`}
        >
          {/* <li>
            <div className="input-group mb-2 ps-2 w-100 cursor-pointer">
              Mois complet
            </div>
          </li> */}
          <li>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState(item.selection)}
              moveRangeOnFirstSelection={false}
              ranges={[state]}
              showDateDisplay={false}
            />
          </li>
          <li className="text-sm-end">
            <p className="d-inline-flex gap-1 mb-0">
              <button
                type="button"
                className="btn  btn-sm bsl-btn-annuler-date"
                onClick={() => {
                  setToggle(false);
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-sm bsl-btn-valider-date"
                onClick={vaidated}
              >
                Valider
              </button>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default DateRangeFilter;
