import React from "react";
import FormButton from "../../../Form/FormButton";
import FormCard from "../../../Form/FormCard";
import ListGroup from "../../../Form/ListGroup";
import ListItems from "../../../Form/ListItems";
import ListItemContents from "../../../Form/ListItemContents";
import { useAccess } from "../../../../hooks/useAccess";

const ShowInfosCommande = ({ order = null, changeIsUpdate }) => {
  //Permissions
  const can_update_order = useAccess(44);

  return (
    <>
      <div className=" container-fluid d-flex mt-2 mb-2  ps-4 pt-3 pb-3 justify-content-end ">
        {order?.order_statut_id == 7 && (
          <span
            className={`badge input-label-size text-success
              } bg-light fw-light ms-2 me-2`}
          >
            <i className="fas fa-check-double text-success me-2"></i>
            Commande à facturer
          </span>
        )}
        {order?.order_statut_id != 7 && can_update_order && (
          <FormButton
            buttonType="button"
            buttonValue="Modifier"
            buttonClass="btn btn-outline-primary input-label-size me-2"
            hasIcon={true}
            iconClass="fas fa-pen me-2"
            loseModal={false}
            buttonOnClick={() => changeIsUpdate(true)}
          />
        )}
      </div>
      <div className="p-2">
        <div className="container-fluid container-sm">
          {/* pour les agents responsable */}

          <FormCard title="Général">
            <ListGroup>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Client"
                    value={order?.order_client_company_name}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Réference client"
                    value={order?.order_client_ref}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Branche"
                    value={order?.order_branch_name}
                    contentClass="col "
                  />
                </div>
              </ListItems>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Date de validation"
                    value={new Date(
                      order?.order_validation_date
                    ).toLocaleDateString()}
                    contentClass="col "
                  />
                </div>
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="contact">
            <ListGroup>
              <ListItems>
                <ListItemContents
                  label="Choix de contact"
                  value={order?.order_contact?.cc_name}
                />
              </ListItems>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Poste"
                    value={order?.order_contact?.cc_post}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="E-mail"
                    value={order?.order_contact?.cc_email}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Téléphone"
                    value={
                      order?.order_contact?.cc_phone_1 !== ""
                        ? order?.order_contact?.cc_phone_1
                        : order?.order_contact?.cc_phone_2
                    }
                    contentClass="col "
                  />
                </div>
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="Origine">
            <ListGroup>
              <ListItems>
                <ListItemContents
                  label="Nom de sa société"
                  value={order?.order_origin_company}
                />
              </ListItems>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Pays"
                    value={order?.order_origin_country_name}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Code postal"
                    value={order?.order_origin_zip_code}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Ville"
                    value={order?.order_origin_city}
                    contentClass="col "
                  />
                </div>
              </ListItems>
              <ListItems>
                <ListItemContents
                  label="Adresse"
                  value={order?.order_origin_adress}
                  contentClass="col "
                />
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="Destination">
            <ListGroup>
              <ListItems>
                <ListItemContents
                  label="Nom de la société"
                  value={order?.order_dest_company}
                />
              </ListItems>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Pays"
                    value={order?.order_dest_country_name}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Code postal"
                    value={order?.order_dest_zip_code}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Ville"
                    value={order?.order_dest_city}
                    contentClass="col "
                  />
                </div>
              </ListItems>
              <ListItems>
                <ListItemContents
                  label="Adresse"
                  value={order?.order_dest_adress}
                  contentClass="col "
                />
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="Marchandises">
            <ListGroup>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Nombre de colis"
                    value={order?.order_goods_nb_colis}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Poids"
                    value={order?.order_goods_weight}
                    contentClass="col "
                  />
                  <ListItemContents
                    label="Numero de tracking/Expedition"
                    value={order?.order_goods_num_track_expedition}
                    contentClass="col "
                  />
                </div>
              </ListItems>
              <ListItems>
                <ListItemContents
                  label="Descriptif"
                  value={order?.order_goods_description}
                  contentClass="col "
                />
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="Livraison">
            <ListGroup>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Date de livraison"
                    value={
                      order?.order_delivery_date == null
                        ? ""
                        : new Date(
                            order?.order_delivery_date
                          ).toLocaleDateString()
                    }
                    contentClass="col-md-4 "
                  />
                  <ListItemContents
                    label="Heure de livraison"
                    value={
                      order?.order_delivery_time == null
                        ? ""
                        : order?.order_delivery_time
                    }
                    contentClass="col-md-4 "
                  />
                </div>
              </ListItems>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Nom du signataire"
                    value={order?.order_delivery_signer_name}
                    contentClass="col-md-4 "
                  />
                </div>
              </ListItems>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Emballage"
                    value={order?.order_delivery_packaging}
                    contentClass="col-md-4 "
                  />
                  <ListItemContents
                    label="D&T"
                    value={order?.order_delivery_D_and_T}
                    contentClass="col-md-4 "
                  />
                  <ListItemContents
                    label="Douane"
                    value={order?.order_delivery_customs}
                    contentClass="col-md-4 "
                  />
                </div>
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="Sous traitants">
            <ListGroup>
              <ListItems>
                <ListItemContents
                  label="Nombre de sous traitants"
                  value={
                    order?.sous_traitant?.length > 0
                      ? order?.sous_traitant?.length
                      : 0
                  }
                />
              </ListItems>
              {order?.sous_traitant?.length > 0
                ? order?.sous_traitant.map((item_s, i) => (
                    <ListItems key={i}>
                      <div className="d-flex p-0 m-0">
                        <ListItemContents
                          label={`Sous Traitant ${i + 1}`}
                          value={item_s?.order_st_st_company_name}
                          contentClass="col "
                        />
                        <ListItemContents
                          label="Coût"
                          value={item_s?.order_st_cost}
                          contentClass="col "
                        />
                        <ListItemContents
                          label="Référence"
                          value={item_s?.order_st_ref}
                          contentClass="col "
                        />
                      </div>
                    </ListItems>
                  ))
                : null}
            </ListGroup>
          </FormCard>
          <FormCard title="Frais divers">
            <ListGroup>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Nom frais divers"
                    value={order?.order_misc_fees_name}
                    contentClass="col-md-4 "
                  />
                  <ListItemContents
                    label="Montant frais divers"
                    value={order?.order_misc_fees_amount}
                    contentClass="col-md-4 "
                  />
                </div>
              </ListItems>
              <ListItems>
                <ListItemContents
                  label="Commentaire"
                  value={order?.order_misc_fees_comment}
                  contentClass="col "
                />
              </ListItems>
            </ListGroup>
          </FormCard>
          <FormCard title="Prix">
            <ListGroup>
              <ListItems>
                <div className="d-flex p-0 m-0">
                  <ListItemContents
                    label="Prix de Vente"
                    value={order?.order_selling_price}
                    contentClass="col-md-4  "
                  />
                  <ListItemContents
                    label="Prix d'Achat"
                    value={order?.order_purchase_price}
                    contentClass="col-md-4  "
                  />
                </div>
              </ListItems>
              <ListItems>
                <ListItemContents
                  label="TVA"
                  value={order?.order_tva + " %"}
                  contentClass="col "
                />
              </ListItems>
            </ListGroup>
          </FormCard>
        </div>
      </div>
    </>
  );
};

export default ShowInfosCommande;
