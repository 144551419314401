import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import "../../styles/Form/inputFile.css";
import showFile from "./showFile";
import FormButton from "./FormButton";

const InputFile = ({
  isUpdate = false,
  onIsUpdate = () => {},
  title = "",
  files = [{ fileType: "pdf", icone: "fa fa-envelope" }], //type avec icone qui va avec
  name_input = "file",
  size_file = 1, //size en MO
  data_file = [],
  set_document = (e) => {},
  deleteFile = (name) => {},
  showValidateBtn = true,
  justeShow = true,
}) => {
  const [file, setFile] = useState([]);
  const [progressValue, setProgressValue] = useState(0);
  const fileTypes = files.map((file) => file.fileType.toLowerCase());

  const [lastAddedFileIndex, setLastAddedFileIndex] = useState(-1); // Indice du dernier fichier ajouté
  const [showProgressBar, setShowProgressBar] = useState(false);

  const add_file = (file) => {
    const isDuplicate = data_file?.some(
      (existingFile) => existingFile.name === file[0].name
    );
    if (!isDuplicate) {
      setFile(file);
    } else {
      Swal.fire({
        title: "Erreur!",
        text: "Le fichier existe déjà dans la liste",
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    set_document(file[0]);
  }, [file]);

  // useEffect(() => {
  //   if (file.length > 0) {
  //     setLastAddedFileIndex(data_file?.length - 1);
  //     setShowProgressBar(true);

  //     const interval = setInterval(() => {
  //       setProgressValue((prevProgress) => {
  //         const newProgress = prevProgress + 1;
  //         if (newProgress >= 90) {
  //           clearInterval(interval);
  //           setShowProgressBar(false);
  //           setProgressValue(0);
  //         }
  //         return newProgress;
  //       });
  //     }, 20);

  //     return () => clearInterval(interval);
  //   }
  // }, [data_file]);

  const onDeleteFile = (index) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Voulez vous supprimer ce fichier?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFile(index);
      }
    });
  };
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col d-flex flex-column align-items-center">
          {isUpdate && justeShow && (
            <div className=" container-fluid d-flex mt-2  ps-4 pt-3 pb-3 justify-content-end ">
              <FormButton
                buttonType="button"
                buttonValue="Modifier"
                buttonClass="btn btn-outline-primary input-label-size me-2"
                hasIcon={true}
                iconClass="fas fa-pen me-2"
                loseModal={false}
                buttonOnClick={onIsUpdate}
              />
            </div>
          )}
          {justeShow && (
            <div className="mt-2 col-4" style={{ display: "contents" }}>
              <FileUploader
                multiple={true}
                handleChange={add_file}
                name={name_input}
                types={fileTypes}
                label="Sélectionnez un fichier ou glissez-déposez ici"
                maxSize={size_file}
                disabled={isUpdate}
                onTypeError={() =>
                  Swal.fire({
                    title: "Erreur!",
                    text: "Extension non prise en compte",
                    icon: "error",
                    position: "center",
                    showConfirmButton: false,
                    timer: 10000,
                  })
                }
                children={
                  <>
                    <label className="sc-aXZVg guZdik  " htmlFor={name_input}>
                      <div className="sc-eqUAAy jWkLDY">
                        <div className="file-description">
                          <span className="file-description-top">
                            Sélectionnez un fichier ou glissez-déposez ici
                          </span>
                          <span className="file-description-bottom">
                            {fileTypes?.map((type, index) => (
                              <span key={index}>{type.toUpperCase()},</span>
                            ))}
                            fichier de {size_file}MB maximum
                          </span>
                        </div>

                        <button
                          className="btn btn-outline-primary btn-sm file-types"
                          onClick={
                            isUpdate
                              ? () => {
                                  Swal.fire({
                                    title: "Erreur!",
                                    text: "Cliquer modifier d'abord",
                                    icon: "error",
                                    position: "center",
                                    showConfirmButton: false,
                                    timer: 10000,
                                  });
                                }
                              : () => {}
                          }
                        >
                          <i className="fas fa-paperclip"></i> Sélectionner
                          fichier
                        </button>
                      </div>
                    </label>
                  </>
                }
              />
            </div>
          )}

          {data_file?.length > 0 && (
            <>
              <div className="mt-2 col-12">
                <div className="p-3 d-flex align-items-center justify-content-between">
                  <p className="m-0 flex-grow-1 fw-bold">{title}</p>
                </div>
              </div>
              <div className="col-12">
                <div
                  className="p-3 d-flex flex-column align-items-center justify-content-between"
                  style={{ gap: "20px" }}
                >
                  {data_file?.map((f, index) => (
                    <div
                      key={index}
                      className={`m-0 d-flex align-items-center justify-content-between ligne_fichier`}
                    >
                      <div className="d-flex align-items-center icon_left">
                        <i
                          className={
                            "mr-2 text-primary " +
                            files.find(
                              (file) =>
                                file.fileType.toLowerCase() ===
                                f?.filename.split(".").pop().toLowerCase()
                            )?.icone
                          }
                        ></i>
                      </div>
                      <p className="m-0 flex-grow-1">
                        {f ? `${f?.filename}` : ""}{" "}
                      </p>

                      <div
                        className={`d-flex align-items-center ${
                          index === lastAddedFileIndex && showProgressBar
                            ? ""
                            : "fadeIn"
                        }`}
                        style={{ gap: "20px" }}
                      >
                        {/* <span className="mr-2 file-description-bottom">
                            {(f.size / (1024 * 1024)).toFixed(2) + " Mo"}
                          </span> */}
                        <i
                          className="fa fa-eye file-description-bottom"
                          onClick={() =>
                            showFile(
                              f.originalname === undefined
                                ? f?.url
                                : `/api/temp/${f?.filename}`
                            )
                          }
                        ></i>
                        {!isUpdate && (
                          <i
                            className="fas fa-trash-alt text-danger"
                            onClick={() => onDeleteFile(f?.filename)}
                          ></i>
                        )}
                      </div>
                    </div>
                  ))}

                  {/* {showProgressBar ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${progressValue}%`,
                          transition: "width 0.5s ease-in-out",
                        }}
                        aria-valuenow={progressValue}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!(isUpdate && justeShow) && showValidateBtn && (
        <div className={"d-flex justify-content-end mt-2 mb-- modal-footer"}>
          <FormButton
            buttonType="submit"
            buttonValue="Valider"
            buttonClass="btn green-button-bsl input-label-size me-2"
            hasIcon={true}
            iconClass="fas fa-check me-2"
            closeModal={false}
          />
        </div>
      )}
    </div>
  );
};

export default InputFile;
