import { configureStore } from "@reduxjs/toolkit";
import tableSlice from "./table/tableSlice";
import searchDropdownSlice from "./searchDropdownSlice";
import filterTableSlice from "./table/filterTableSlice";
import btnIconSlice from "./table/btnIconSlice";
import dateRangeSlice from "./dateRangeSlice";

export const store = configureStore({
  reducer: {
    dateRange: dateRangeSlice,
    table: tableSlice,
    dropdown: searchDropdownSlice,
    filterTable: filterTableSlice,
    btnIconSlice: btnIconSlice,
  },
});
export const RootState = store.getState;
export const AppDispatch = store.dispatch;
