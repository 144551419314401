import React, { useEffect, useRef, useState } from "react";
import "../../../../styles/Modals/TemplateEmail.css";
import FormButton from "../../../Form/FormButton";
import { FileUploader } from "react-drag-drop-files";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import CheckBox from "../../../Form/CheckBox";

const TemplateEmail = ({
  nom = "",
  modalId = "addNewModal",
  modalClass = "",
  isShowModale = false,
  showTemplateEmail = (e) => {},
  idUser = 0,
  demande = {},
  answer = {},
  mail_data = {},
  closeResponse = (e) => {},
  getData = () => {},
}) => {
  //mail variables

  const [mail_from_name, set_mail_from_name] = useState("");

  const [mail_sender, set_mail_sender] = useState("");

  const [mail_receiver, set_mail_receiver] = useState("");

  const [mail_receiver_name, set_mail_receiver_name] = useState("");

  const [mail_object, set_mail_object] = useState();

  const [data_file, set_data_file] = useState([]);
  const [devis_pj, set_devis_pj] = useState(false);

  const files = [{ fileType: "pdf", icone: "fa fa-file" }];

  const pjContainer = useRef(null);

  const filetypes = files.map((file) => file.fileType.toLowerCase());

  const [mail_model, set_mail_model] = useState("");

  const [mail_sending, set_mail_sending] = useState(false);
  //end of mail variables

  function deletePj(d_filename) {
    set_data_file(data_file.filter((d) => d.filename != d_filename));
  }

  const handleAddPj = async (d_file) => {
    const formData = new FormData();
    formData.append("file", d_file[0]);
    const { loading, data, errors } = await FetchData(
      `/api/request/upload/doc/${idUser}`,
      {
        method: "PUT",
        body: formData,
      },
      true
    );
    if (errors) {
      Swal.fire({
        title: "Erreur!",
        text: errors,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      if (data.status === 200) {
        let file = {
          filename: d_file[0].name, //véritable nom du fichier
          path: data.data.filename, //nom du fichier dans le back-end pour l'envoi des mails
        };
        set_data_file((v) => [...v, file].filter((d) => d));
        pjContainer.current.scrollTop = pjContainer.current.scrollHeight;
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const PjHandler = ({ name_input = "", fileTypes = [], size_file = 23 }) => {
    return (
      <div className="col">
        <div className="w-100" htmlFor={name_input}>
          <div className="d-flex align-items-center">
            <button className="btn btn-outline-none btn-sm " onClick={() => {}}>
              <i className="fas fa-paperclip"></i>
            </button>
            <div className="file-description-bottom">
              {fileTypes?.map((type, index) => (
                <React.Fragment key={index}>
                  {type.toUpperCase()},
                </React.Fragment>
              ))}
              fichier de {size_file}MB maximum
            </div>
          </div>
        </div>
      </div>
    );
  };

  async function sendMail() {
    set_mail_sending(true);
    let mail = {
      from: {
        name: mail_from_name,
        address: "<bsl.app@outlook.com>",
      },
      to: mail_receiver,
      replyTo: mail_sender,
      subject: mail_object,
      text: mail_model,
      attachments: data_file,
      req_as_id: mail_data?.request_answer_id,
      add_pj: devis_pj,
    };

    try {
      let url = "/api/request/send-mail";
      let options = {
        method: "POST",
        body: JSON.stringify(mail),
      };

      const { loading, data, error } = await FetchData(url, options);

      if (data.status === 200) {
        Swal.fire({
          title: "Succès!",
          text: "Mail envoyé et demande répondu avec succès",
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        showTemplateEmail(false);
        getData();
        closeResponse();
      } else {
        Swal.fire({
          title: "Erreur!",
          text: "Mail non-envoyé!",
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        set_mail_sending(false);
      }
    } catch (error) {
      console.error("Oups une erreur du serveur!!!\n", error);
      set_mail_sending(false);
    }
  }

  useEffect(() => {
    if (JSON.stringify(mail_data) !== "{}") {
      set_mail_from_name(mail_data.from.name);
      set_mail_receiver_name(mail_data.to.name);
      set_mail_receiver(mail_data.to.address);
      set_mail_sender(mail_data.replyTo);
      set_mail_model(mail_data?.text);
      set_mail_object("DEVIS N°" + demande?.numero);
    }
  }, [mail_data, demande?.numero]);

  return (
    <>
      <div
        className={
          "modal fade " +
          modalClass +
          " " +
          (isShowModale && " show bsl-show-modal ")
        }
        id={modalId}
        tabIndex="-1"
        aria-labelledby={modalId + "Label"}
        aria-hidden={isShowModale ? null : "true"}
        aria-modal={isShowModale ? "true" : null}
        role={isShowModale ? "dialog" : null}
        style={
          isShowModale === true ? { display: "block" } : { display: "none" }
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ps-25 template-mail-size ">
          <div className="modal-content h-100 w-100">
            <div className="modal-header p-0 pt-2 pb-1">
              <div className="col-11 ps-2">
                <h1 className="modal-title fs-5">{`DEVIS N°${demande?.numero}`}</h1>
              </div>
              <div className="col-1 d-flex justify-content-center pe-3">
                {mail_sending === false && (
                  <button
                    className="btn-close"
                    onClick={() => {
                      showTemplateEmail(false);
                      closeResponse();
                    }}
                  ></button>
                )}
              </div>
            </div>
            <div className="modal-body w-100 ps-2 pe-2 pt-2 p-0">
              <div className="d-flex align-items-center mb-2">
                <label
                  className="input-label-size form-label me-2 pt-2 pe-3"
                  htmlFor="mail_sender"
                >
                  De :
                </label>
                <input
                  type="mail"
                  id="mail_sender"
                  className="input-label-size form-control"
                  value={mail_sender}
                  onChange={(e) => set_mail_sender(e.target.value)}
                  placeholder="dodo@gmail.com"
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label
                  className="input-label-size form-label me-2 pt-2 pe-4"
                  htmlFor="mail_object"
                >
                  A :
                </label>
                <input
                  type="mail"
                  id="mail_object"
                  className="input-label-size form-control"
                  value={mail_receiver}
                  onChange={(e) => set_mail_receiver(e.target.value)}
                  placeholder="dadou@hotmail.com"
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label
                  className="input-label-size form-label me-2 pt-2 "
                  htmlFor="mail_receiver"
                >
                  Objet :
                </label>
                <input
                  type="mail"
                  id="mail_object"
                  className="input-label-size form-control"
                  value={`DEVIS N°${demande?.numero}`}
                  onChange={(e) => set_mail_object(e.target.value)}
                  placeholder="ex: Envoi du devis de la demande"
                />
              </div>
              <div className="form-control p-0">
                <textarea
                  className="w-100 form-control input-label-size text-wrap mail-textarea-size "
                  rows={15}
                  cols={12}
                  value={mail_model}
                  onChange={(e) => {
                    set_mail_model(e.target.value);
                  }}
                />
                <div className="card border-0 w-100 pj-size">
                  <div
                    className="card-body overflow-y-auto pb-5 pj-size-body"
                    ref={pjContainer}
                  >
                    {devis_pj && (
                      <div className="p-3 align-items-left justify-content-between ligne_fichier">
                        <i className="fa fa-file d-inline-block mr-2 text-primary"></i>
                        <span className="d-inline-block ms-3">
                          {demande?.req_as_pdf_name}
                        </span>
                      </div>
                    )}
                    {data_file?.length > 0 && (
                      <>
                        <div className="col-12">
                          <div
                            className="p-3 d-flex flex-column align-items-center justify-content-between"
                            style={{ gap: "20px" }}
                          >
                            {data_file?.map((f, index) => (
                              <div
                                key={index}
                                className={`m-0 d-flex align-items-center justify-content-between ligne_fichier`}
                              >
                                <div className="d-flex align-items-center icon_left">
                                  <i
                                    className={
                                      "mr-2 text-primary " +
                                      files.find(
                                        (file) =>
                                          file.fileType.toLowerCase() ===
                                          f?.filename
                                            .split(".")
                                            .pop()
                                            .toLowerCase()
                                      )?.icone
                                    }
                                  ></i>
                                </div>
                                <p className="m-0 flex-grow-1">
                                  {f ? `${f?.filename}` : ""}{" "}
                                </p>
                                {/* <span className="mr-2 file-description-bottom">
                            {(f.size / (1024 * 1024)).toFixed(2) + " Mo"}
                          </span> */}

                                <i
                                  className="fas fa-trash-alt text-danger"
                                  onClick={() => deletePj(f?.filename)}
                                ></i>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="card-footer bg-light border-0 pj-size-footer m-0">
                    <FileUploader
                      multiple={true}
                      handleChange={handleAddPj}
                      children={
                        <>
                          <PjHandler
                            name_input="mail-pjs"
                            fileTypes={filetypes}
                            set_data_file={set_data_file}
                          />
                        </>
                      }
                    />
                  </div>
                  <div className="px-3 pt-1">
                    <CheckBox
                      id="pj-devis-mail"
                      label="Ajouter le devis dans la pièce jointe"
                      onChange={set_devis_pj}
                      value={devis_pj}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex">
              {mail_sending === false ? (
                <FormButton
                  buttonValue={`Envoyer`}
                  buttonClass="btn green-button-bsl input-label-size me-2 col-3"
                  hasIcon={true}
                  iconClass="fas fa-check me-2"
                  closeModal={false}
                  buttonOnClick={(e) => {
                    sendMail();
                  }}
                />
              ) : (
                <FormButton
                  buttonValue={`Envoi en cours`}
                  buttonClass="btn green-button-bsl input-label-size me-2 col-3"
                  hasIcon={true}
                  iconClass="fas fa-repeat me-2"
                  closeModal={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateEmail;
