import React, { useCallback, useEffect, useRef } from "react";
import "./../../styles/Tables/tableHead.css";
import FilterRound from "../Form/FilterRound";
import { useDispatch, useSelector } from "react-redux";
// import updateFilter from "../../store/table/tableSlice";
import {
  updateFilter,
  updateIndexFilter,
  search,
  setTableHeadFilter,
} from "../../store/table/tableSlice";
import useSearchTable from "../../hooks/table/useSearchTable";
import { selectedFilter } from "../../store/table/filterTableSlice";
function TableHeadFilter({ goUrl = false }) {
  return <>{goUrl ? <UrlTableHeadFilter /> : <NormalTableHeadFilter />}</>;
}

function NormalTableHeadFilter() {
  const dispatch = useDispatch();
  const search_value = useSelector((state) => state.table.search_value);
  let data = useSelector((state) => state.table.filter_data);
  const { searchValue, searchChange } = useSearchTable(search_value);

  const elementRef = useRef(null);
  useEffect(() => {
    let heigthDiv = elementRef?.current?.clientHeight;
    dispatch(setTableHeadFilter(heigthDiv ? heigthDiv : 0));
  }, [elementRef, data]);
  return (
    <>
      <div className="w-100 border-top" ref={elementRef}>
        <div className="table-tabs">
          <div className="tabs-rows">
            {data.map((v, i) => (
              <FilterRound
                key={i}
                text={v.label}
                number={v.number}
                is_active={v.selected}
                onClick={() => {
                  dispatch(updateFilter(i));
                  dispatch(updateIndexFilter(i));
                  searchChange(search_value);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function UrlTableHeadFilter() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.filterTable.filter_state);

  const clickFilter = useCallback((key) => {
    dispatch(selectedFilter(key));
  }, []);

  const elementRef = useRef(null);
  useEffect(() => {
    let heigthDiv = elementRef?.current?.clientHeight;
    dispatch(setTableHeadFilter(heigthDiv ? heigthDiv : 0));
  }, [elementRef, data]);

  return (
    <>
      <div className="w-100 border-top" ref={elementRef}>
        <div className="table-tabs">
          <div className="tabs-rows">
            {data.map((v, i) => (
              <FilterRound
                key={i}
                text={v.label}
                number={v.number}
                is_active={v.selected}
                onClick={() => {
                  clickFilter(v.key);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TableHeadFilter;
