import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value_search: {},
  data_label: {},
};
const searchDropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    initDropdown: (state, action) => {
      let data = { ...state.value_search };
      let data_label = { ...state.data_label };
      if (action.payload.reference_name) {
        data[action.payload.reference_name] = action.payload.value_search;
        data_label[action.payload.reference_name] = action.payload.data_label;
      }
      state.value_search = data;
      state.data_label = data_label;
    },
  },
});

export const { initDropdown } = searchDropdownSlice.actions;
export default searchDropdownSlice.reducer;
