import React, { useMemo, useEffect, useState, useRef } from "react";
import Form from "../../../Form/Form";
import ModalForAddNew from "../../ModalForAddNew";
import FormSection from "../../../Form/FormSection";
import FormButton from "../../../Form/FormButton";
import SelectBox from "../../../Form/SelectBox";
import Input from "../../../Form/Input";
import validator from "validator";
// import Input2 from "../../Form/Input2";
import Swal from "sweetalert2";
import { FetchData } from "../../../../hooks/FetchData";
import ErrorList from "../../../Form/ErrorList";
import { useAccess } from "../../../../hooks/useAccess";

const AddUser = ({
  connectedUser = {},
  user,
  getUsers,
  modalData = {},
  isShowModal = false,
  handleCloseModal = function () {},
  list_role = [],
  list_civilities = [],
}) => {
  //Permissions
  const can_update_user = useAccess(48);

  const formRef = useRef(null);
  //state des inputs
  const [id, setID] = useState("");
  const [lname, setLname] = useState("");
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [profil, setProfil] = useState(0);
  const [civilitie, setCivilitie] = useState(0);

  //Stocker les erreur
  const [error_message, setError_message] = useState({
    id: "",
    lname: "",
    fname: "",
    email: "",
    username: "",
    password: "",
    profil: "",
    civilite: "",
  });

  //les fields requierd
  const required_input = "prenom nom mail identifiant password";
  const required_select = ["profil", "civilite"];

  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    setProfiles(list_role);
  }, [list_role]);

  const [civilities, setCivilities] = useState([]);

  useEffect(() => {
    setCivilities(list_civilities);
  }, [list_civilities]);

  useEffect(() => {
    if (!isEmptyObject(user)) {
      setID(user.id);
      setLname(user.lname);
      setFname(user.fname);
      setEmail(user.email);
      setUsername(user.username);
      setProfil(user.id_role);
      setCivilitie(user.id_civilitie);
      user.username.length > 0
        ? setPassword(user.username + "BSL-2024-Dev")
        : setPassword("");
    } else {
      setID("");
      setLname("");
      setFname("");
      setEmail("");
      setUsername("");
      setProfil(0);
      setCivilitie(0);
      setPassword("");
    }

    let reseted_error_message = {
      id: "",
      lname: "",
      fname: "",
      email: "",
      username: "",
      password: "",
      profil: "",
      civilite: "",
    };
    setError_message(reseted_error_message);
    setError_list([]);
  }, [user]);

  const [error_list, setError_list] = useState([]);

  //handle scroll to top on errors
  const modalBodyAddUser = useRef(null);
  //components functions

  const handleCreate = async (e, user) => {
    e.preventDefault();
    try {
      const { loading, data, errors } = await FetchData("/api/user", {
        method: "POST",
        body: JSON.stringify({ user }),
      });
      if (data.status === 201) {
        Swal.fire({
          title: "Succès",
          text: "Insertion de l'utilisateur effectuée.",
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        getUsers();
        handleCloseModal();
        setError_message({
          id: "",
          lname: "",
          fname: "",
          email: "",
          username: "",
          password: "",
          profil: "",
          civilite: "",
        });
      } else {
        const { erreur } = data;

        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const handleUpdate = async (e, user) => {
    e.preventDefault();
    try {
      const { loading, data, errors } = await FetchData(
        `/api/user/${parseInt(user.id)}`,
        {
          method: "PUT",
          body: JSON.stringify({ user }),
        }
      );
      if (data.status === 200) {
        Swal.fire({
          title: "Succès",
          text: "Modification de l'utilisateur effectuée.",
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        getUsers();
        handleCloseModal();
        setError_message({
          id: "",
          lname: "",
          fname: "",
          email: "",
          username: "",
          password: "",
          profil: "",
          civilite: "",
        });
      } else {
        const { erreur } = data;

        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function resetError() {
    handleCloseModal();
    setError_message({
      id: "",
      lname: "",
      fname: "",
      email: "",
      username: "",
      password: "",
      profil: "",
      civilite: "",
    });
    setError_list([]);
  }

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }

    return isEmpty;
  }

  function handleChangeName(nameChanger = (e) => {}, theName, nameCategory) {
    let newName = theName;
    nameChanger(newName);

    if (nameCategory == 1) {
      //it is a firstName
      setEmail(autoCompleteEmail(newName, lname));
    } else if (nameCategory == 2) {
      //it is a LastName
      setEmail(autoCompleteEmail(fname, newName));
    }
  }

  function autoCompleteEmail(firstName = "", lastName = "") {
    firstName =
      firstName.length > 0 ? `${firstName.split(" ")[0].toLowerCase()}` : "";
    lastName =
      lastName.length > 0 ? `${lastName.split(" ")[0].toLowerCase()}.` : "";

    if (firstName.length === 0 && lastName.length > 0) {
      lastName = lastName.slice(0, -1);
    }

    let newEmail = email;
    if (isEmptyObject(user)) {
      if (firstName.length === 0 && lastName.length === 0) {
        newEmail = "";
      } else {
        newEmail = `${lastName}${firstName}@bslogistics.com`;
        //Reset errors for mail if autocompleting it
        let newErrorList = error_list;
        let newErrorMessage = error_message;
        newErrorList = newErrorList.filter((d) => d !== "Mail");
        newErrorMessage = { ...newErrorMessage, mail: "" };
        setError_list(newErrorList);
        setError_message(newErrorMessage);
      }
    }

    return newEmail;
  }

  const testInputValueOnSubmit = (e) => {
    e.preventDefault();

    const inputs = new FormData(formRef.current);
    const errorListLabel = {
      numero: "Numéro de collaborateur",
      profil: "Profil",
      nom: "Nom",
      prenom: "Prénom",
      mail: "Mail",
      identifiant: "Identifiant",
      password: "Mot de passe",

      civilite: "Civilité",
    };
    let newErrorList = [];
    let errors = error_message;
    let validForm = true;
    for (const key of inputs.keys()) {
      // client_bsl_num
      if (inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        newErrorList.push(errorListLabel[key]);
        validForm = false;
      }
    }
    required_select.forEach((field) => {
      if (!inputs.get(field) && inputs.get(field) === null) {
        errors[field] = "Champ non-vide";
        errors = { ...errors, field: "Champ non-vide" };
        newErrorList.push(errorListLabel[field]);
        validForm = false;
      }
    });
    if (
      inputs.get("mail")?.length > 0 &&
      !validator.isEmail(inputs.get("mail"))
    ) {
      errors["mail"] = "E-mail invalide";
      errors = { ...errors, key: "E-mail invalide" };
      newErrorList.push(errorListLabel["email"]);
      validForm = false;
      // newErrorList.push("E-mail");
    }
    setError_list(newErrorList);
    setError_message(errors);
    if (validForm) {
      const newUser = {
        username: inputs.get("identifiant"),
        password: inputs.get("password"),
        email: inputs.get("mail"),
        role: parseInt(inputs.get("profil")),
        fname: inputs.get("prenom"),
        lname: inputs.get("nom"),
        civilitie: inputs.get("civilite"),
      };
      if (
        newUser.username !== "" &&
        newUser.password !== "" &&
        newUser.email !== "" &&
        validator.isEmail(newUser.email) &&
        newUser.fname !== "" &&
        newUser.lname !== ""
      ) {
        if (!isEmptyObject(user) && newUser !== null) {
          newUser["id"] = id;
          handleUpdate(e, newUser);
        } else {
          handleCreate(e, newUser);
        }
      }
    } else {
      modalBodyAddUser.current.scrollTop = 0;
    }
  };

  const disable_update_for_non_super_admin = isEmptyObject(user)
    ? false
    : user?.id_role !== 1
    ? !can_update_user
    : !(can_update_user && connectedUser?.id_role === 1);

  return (
    <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
      <div className="modal-header container-fluid">
        <h1 className="modal-title fs-5 ms-2" id={modalData?.modalId + "Label"}>
          {user.numero
            ? `${user.fname} ${user.lname} `
            : "Nouveau collaborateur"}
          <span className="badge input-label-size bg-primary-subtle text-primary-emphasis fw-light ms-2">
            {user.numero ? user.user_role_name : "Nouveau"}
          </span>
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={resetError}
        ></button>
      </div>
      <Form
        formData={{
          type: "form",
          id: "addCollaborator",
          formMethod: "post",
          formAction: "#",
          formClass: "",
        }}
        submitFunction={testInputValueOnSubmit}
        formRef={formRef}
      >
        <div ref={modalBodyAddUser} className="modal-body p-0">
          {error_list.length > 0 ? <ErrorList errorList={error_list} /> : null}
          <FormSection
            formSectionData={{
              type: "section",
              title: "Général",
              sectionClass: "border-bottom",
            }}
          >
            <Input
              type={"text"}
              blockClass={"mb-3 me-2 col"}
              id={"numero"}
              labelClass={""}
              label={"Numéro de collaborateur"}
              inputClass=""
              placeholder="Numéro de collaborateur"
              isRequired={false}
              isDisabled={true}
              errorMessage={error_message["numero"]}
              value={user.numero}
              onChange={setID}
            />
            <SelectBox
              type="select"
              blockClass=""
              id="profil"
              labelClass=""
              label="Profil"
              placeholder="Profil"
              selectClass=""
              isRequired={true}
              isDisabled={disable_update_for_non_super_admin}
              selectOptions={profiles}
              cle="user_role_id"
              value="user_role_name"
              value_selected={profil}
              errorMessage={error_message["profil"]}
              onChange={setProfil}
            />
            <div className="d-flex flex-row">
              <SelectBox
                type="select"
                blockClass="mb-3 me-2 col"
                id="civilite"
                labelClass=""
                label="Civilité"
                selectClass="input-label-size "
                isRequired={true}
                isDisabled={disable_update_for_non_super_admin}
                selectOptions={civilities}
                placeholder="Civilité"
                cle="user_civility_id"
                value="user_civility_name"
                value_selected={civilitie}
                errorMessage={error_message["civilite"]}
                onChange={setCivilitie}
              />
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="prenom"
                labelClass=""
                label="Prénom"
                inputClass="custom-input-colors"
                placeholder="Prénom"
                isRequired={true}
                isDisabled={disable_update_for_non_super_admin}
                errorMessage={error_message["prenom"]}
                value={fname}
                onChange={(e) => handleChangeName(setFname, e, 1)}
              />
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="nom"
                labelClass=""
                label="Nom"
                inputClass="custom-input-colors"
                placeholder="Nom"
                isRequired={true}
                isDisabled={disable_update_for_non_super_admin}
                errorMessage={error_message["nom"]}
                value={lname}
                onChange={(e) => handleChangeName(setLname, e, 2)}
              />
            </div>
            <Input
              type="mail"
              blockClass="mb-3 me-2 col"
              id="mail"
              labelClass=""
              label="Mail"
              inputClass="custom-input-colors"
              placeholder="Mail"
              isRequired={true}
              isDisabled={disable_update_for_non_super_admin}
              errorMessage={error_message["mail"]}
              value={email}
              onChange={setEmail}
            />
          </FormSection>
          <FormSection
            formSectionData={{ type: "section", title: "ID / Mot de passe" }}
          >
            <div className="d-flex flex-row">
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="identifiant"
                labelClass=""
                label="Identifiant"
                inputClass="custom-input-colors"
                placeholder="Identifiant"
                isRequired={true}
                isDisabled={disable_update_for_non_super_admin}
                errorMessage={error_message["identifiant"]}
                value={username}
                onChange={setUsername}
              />
              <Input
                type="password"
                blockClass="mb-3 me-2 col"
                id="password"
                labelClass=""
                label="Mot de passe"
                inputClass="custom-input-colors"
                placeholder="Mot de passe"
                isRequired={true}
                isDisabled={disable_update_for_non_super_admin}
                errorMessage={error_message["password"]}
                value={password}
                onChange={setPassword}
              />
            </div>
          </FormSection>
        </div>
        {!disable_update_for_non_super_admin && (
          <div className={"d-flex justify-content-end mt-2 mb-- modal-footer"}>
            <FormButton
              buttonType="button"
              buttonValue="Annuler"
              buttonClass="btn text-secondary input-label-size me-2"
              hasIcon={true}
              iconClass="far fa-times-circle me-2 bg-light"
              closeModal={true}
              buttonOnClick={resetError}
            />
            <FormButton
              buttonType="submit"
              buttonValue="Valider"
              buttonClass="btn green-button-bsl input-label-size me-2"
              hasIcon={true}
              iconClass="fas fa-check me-2"
              closeModal={false}
            />
          </div>
        )}
      </Form>
    </ModalForAddNew>
  );
};

export default AddUser;
