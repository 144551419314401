import React, { useState, useEffect } from "react";
import Select from "react-select";

const SelectBoxSearch = ({
  blockClass = "",
  id = "select",
  labelClass = "",
  label = "labelName",
  selectClass = "",
  isRequired = false,
  selectOptions = [],
  cle = "id",
  value = "label",
  placeholder = "",
  onChange,
  errorMessage = "",
  defaultValue = 0,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      outline: "none",
      borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#DEE2E6",
      },
      "&:active": {
        borderColor: "#DEE2E6 !important",
      },
      minWidth: "200px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#e0e0e0"
        : state.isFocused
        ? "#e0e0e0"
        : "white",
      color: state.isSelected ? "black" : "black",
      "&:hover": {
        backgroundColor: "#d0d0d0",
      },
    }),
  };
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    setInputError(errorMessage);
  }, [errorMessage]);

  const [selectValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    const value_s = selectOptions
      .filter((d) => {
        return d[cle] == defaultValue;
      })
      .map((option, index) => ({
        value: option[cle],
        label: option[value],
      }));
    if (value_s.length > 0) {
      setSelectedValue(value_s[0]);
    } else {
      setSelectedValue({
        value: 0,
        label: placeholder,
      });
    }
  }, [defaultValue, selectOptions]);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onChange(selectedOption.value);
    if (isRequired && errorMessage !== "") {
      setInputError("");
    }
  };

  return (
    <>
      <div className={"mb-3" + blockClass} style={{ zIndex: 9999 }}>
        <label
          htmlFor={id}
          className={
            "form-label input-label-size position-relative " + labelClass
          }
        >
          {label}
          {isRequired && (
            <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
              *
            </span>
          )}
        </label>
        <Select
          className={
            "bg-light " +
            selectClass +
            (inputError?.length > 5
              ? "border border-danger invalid-input-colors rounded"
              : " ")
          }
          id={id}
          name={id}
          title={placeholder}
          options={selectOptions.map((option, index) => ({
            value: option[cle],
            label: option[value],
          }))}
          value={selectValue}
          placeholder={placeholder}
          isSearchable
          styles={customStyles}
          onChange={(value) => handleChange(value)}
        />
        {inputError?.length > 0 && (
          <span className="input-error-message">
            <i className="fa-solid fa-circle-exclamation text-danger me-2"></i>
            {inputError}
          </span>
        )}
      </div>
    </>
  );
};

export default SelectBoxSearch;
