import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/Pages/login.css";
import { useUser } from "../../UserContext";
import { FetchData } from "../../hooks/FetchData";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUser, fetchUserData, user: userData } = useUser();
  const [shouldHideDialog, setShouldHideDialog] = useState(false);
  const [shouldgo, setShouldgo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleInputChange = () => {
    setErrorMessage("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { loading, data, errors } = await FetchData("/api/login", {
        method: "POST",
        body: JSON.stringify({ username, password }),
      });
      setIsLoading(false);
      if (data.status === 200) {
        await fetchUserData();
        setIsLoggedIn(true);
        setTimeout(() => {
          setShouldHideDialog(true);
          setTimeout(() => {
            setShouldgo(true);
            setTimeout(() => {
              navigate("/Accueil");
            }, 1000);
          }, 1000);
        }, 800);
      } else {
        const { erreur } = data;
        setErrorMessage(erreur);
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  return (
    <div className="connexion">
      <div
        className={`frame ${
          shouldgo ? "slide-out" : shouldHideDialog ? "transct" : ""
        } `}
      >
        <div className="nav">
          <div className="logo">
            <img className="img" src="img/Logo.svg" alt=" " />
          </div>
        </div>
      </div>
      {!shouldHideDialog && (
        <div className={`dialog-connexion-wrapper`}>
          <div className="dialog-connexion">
            <div className="div">
              <div className="text-wrapper">Bienvenue</div>
              {isLoggedIn && (
                <div className="text-wrapper-2">{userData?.fname}</div>
              )}
            </div>
            {!isLoggedIn && (
              <form onSubmit={handleLogin}>
                <div className="frame-2">
                  <div className="input">
                    <div className="label">
                      <div className="field-label">Identifiant</div>
                    </div>
                    <div
                      className={`input-2  ${
                        errorMessage === "id" ? "error" : ""
                      }`}
                    >
                      <div
                        className={`input-field  ${
                          errorMessage === "id" ? "error" : ""
                        }`}
                      >
                        <img
                          className="img-2 pd"
                          src="img/mingcute_user-1-line.svg"
                          alt=" "
                        />
                        <input
                          type="text"
                          placeholder="Identifiant"
                          className={`field-label-2 ${
                            errorMessage === "id" ? "error" : ""
                          }`}
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                            handleInputChange();
                          }}
                          required
                        />
                      </div>
                    </div>
                    {errorMessage === "id" && (
                      <div className="error-message">
                        <img
                          className="error-icon"
                          src="img/error-alt.svg"
                          alt="Erreur"
                        />
                        <p>Identifiant invalide</p>
                      </div>
                    )}
                  </div>
                  <div className="input">
                    <div className="label">
                      <div className="field-label">Mot de passe</div>
                    </div>
                    <div
                      className={`input-2  ${
                        errorMessage === "psw" ? "error" : ""
                      }`}
                    >
                      <div
                        className={`input-field  ${
                          errorMessage === "psw" ? "error" : ""
                        }`}
                      >
                        <img
                          className="img-2 pd"
                          src="img/mingcute_unlock-line.svg"
                          alt=" "
                        />
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Mot de passe"
                          className={`field-label-2 ${
                            errorMessage === "psw" ? "error" : ""
                          }`}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            handleInputChange();
                          }}
                          required
                        />
                        <img
                          className="img-2"
                          src={`img/mingcute_eye-${
                            showPassword ? "close" : "2"
                          }-line.svg`}
                          alt=" "
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    </div>
                    {errorMessage === "psw" && (
                      <div className="error-message">
                        <img
                          className="error-icon"
                          src="img/error-alt.svg"
                          alt=" "
                        />
                        <p>Mot de passe invalide </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="frame-3">
                  <button className="button-primary">
                    {isLoading ? (
                      <div className="spinner"></div>
                    ) : (
                      <div className="label-2">Se connecter</div>
                    )}
                  </button>

                  <div className="button-ghost" onClick={handleForgotPassword}>
                    <p className="p">J'ai oublié mon mot de passe</p>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
