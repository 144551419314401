import React, { useEffect, useState } from "react";
import FilterRound from "../Form/FilterRound";
import Select from "react-select";
import DateRangeFilter from "../Form/DateRangeFilter";
import SelectBoxSearch from "../Form/SelectBoxSearch";
const StatsFilterRound = ({
  data_menu = [],
  clickFilter = () => {},
  activeChartFilter = [],
  filtered = 0,
  onChangeFilter = () => {},
  filterTitle = "Tous les clients",
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      outline: "none",
      borderColor: state.isFocused ? "#DEE2E6" : "#DEE2E6",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#DEE2E6",
      },
      "&:active": {
        borderColor: "#DEE2E6 !important",
      },
      minWidth: "350px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#e0e0e0"
        : state.isFocused
        ? "#e0e0e0"
        : "white",
      color: state.isSelected ? "black" : "black",
      "&:hover": {
        backgroundColor: "#d0d0d0",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const [valueFiltered, setValueFiltered] = useState(filtered.id);
  const [title, setTitle] = useState("");

  function changeFiltered(e) {
    let newValue = e.target.value;
    let newFilter = activeChartFilter.filter((d) => d.id == newValue)[0];
    onChangeFilter(newFilter);
    setValueFiltered(newValue);
  }

  useEffect(() => {
    setValueFiltered(filtered.id);
  }, [filtered]);

  useEffect(() => {
    setTitle(filterTitle);
  }, [filterTitle]);

  return (
    <div className="card w-100 bsl-bar-chart-card col col-sm-12">
      <div className="card-header">
        <div className="w-100 ">
          <div className="table-tabs border-bottom-0 p-1 align-items-center">
            <div className="tabs-rows">
              {data_menu.map((v, i) => (
                <FilterRound
                  key={i}
                  text={v.label}
                  number={v.number}
                  is_active={v.selected}
                  onClick={() => clickFilter(v.key)}
                />
              ))}
            </div>
            <div className="col-3  text-left">
              <select
                disabled={false}
                className={
                  "p-1 input-text-size text-secondary border-0 rounded ms-2 w-100"
                }
                onChange={(e) => changeFiltered(e)}
                value={valueFiltered}
              >
                {activeChartFilter.map((cf_data, cf_index) => (
                  <option
                    className={"ps-2 pe-2 "}
                    key={"filtre-" + cf_index}
                    value={cf_data.id}
                  >
                    {cf_data.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsFilterRound;
