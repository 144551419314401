import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./../../styles/stats/barChart.css";
import FilterRound from "../Form/FilterRound";

function BarChartCustom({ data_bar, setAnnee, annee, chartMenu = [] }) {
  const [key, setKey] = useState(0);
  const [all_data, setAll_data] = useState(data_bar);
  let hasData = true;
  const chartRef = useRef(null);

  all_data?.ca?.data?.forEach((data) => {
    data.y !== 0 && (hasData = false);
  });

  const [activeTab, setActiveTab] = useState("nb_commande");
  const [data_menu, setData_menu] = useState();

  const init_nb_commandes = (activeTab) => ({
    chart: {
      type: "column",
      height: 500,
    },
    title: {
      text: `${annee - 1}-${annee}`,
      align: "center",
      style: {
        fontSize: "15px",
        fontWeight: "normal",
      },
    },
    xAxis: {
      categories:
        all_data[activeTab]?.categorie[0] !== undefined
          ? all_data[activeTab]?.categorie[0]
          : [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Nombre de commandes",
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total + " " + all_data[activeTab]?.devis;
        },
      },
    },
    legend: {
      align: "left",
      x: 10,
      verticalAlign: "top",
      y: 2,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat:
        "{series.name}: {point.y}<br/>Total: {point.stackTotal} " +
        all_data[activeTab]?.devis,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          verticalAlign: "center",
        },
      },
    },
    series: all_data[activeTab]?.data.map((item) => ({
      data: item.data,
      name: item?.name,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y + " " + all_data[activeTab]?.devis;
        },
      },
    })),
  });

  const [data_option, setData_option] = useState();

  useEffect(() => {
    setData_menu(chartMenu);
  }, [chartMenu]);

  useEffect(() => {
    setData_option(init_nb_commandes(activeTab));
  }, [activeTab, all_data, annee]);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    setAll_data(data_bar);
  }, [data_bar]);

  const clickFilter = (key) => {
    setData_menu(data_menu?.map((v) => ({ ...v, selected: v.key === key })));
    setActiveTab(key);
  };

  const nextClick = () => {
    setAnnee(annee + 1);
    // execute setAll_data here
  };
  const prevClick = () => {
    setAnnee(annee - 1);
    // execute setAll_data here
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        chart.reflow(); // Redimensionner le graphique
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let data = all_data;
  }, [all_data]);

  return (
    <>
      <div className="card w-100 bsl-bar-chart-card">
        <div className="card-header">
          <div className="w-100 ">
            <div className="table-tabs border-bottom-0 p-1">
              <div className="tabs-rows">
                {data_menu?.map((v, i) => (
                  <FilterRound
                    key={i}
                    text={v.label}
                    number={v.number}
                    is_active={v.selected}
                    onClick={() => clickFilter(v.key)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body w-100">
          <div className="row w-100 row-bar-chart">
            <div className="col ">
              <i
                className="float-left fas fa-angle-left cursor-pointer ms-4"
                onClick={prevClick}
              ></i>
            </div>
            <div className="col">
              {annee < new Date().getFullYear() && (
                <i
                  className="float-right fas fa-angle-right cursor-pointer"
                  onClick={nextClick}
                ></i>
              )}
            </div>
          </div>
          <div className="bsl-bar-chart ">
            <HighchartsReact
              key={key}
              highcharts={Highcharts}
              options={data_option}
              ref={chartRef}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BarChartCustom;
