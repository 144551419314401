import React, { useState } from "react";
import "./../../styles/stats/widget.css";
function Widget({ data, clickFun = () => {} }) {
  const [icon, setIcon] = useState(data?.icon);

  const handleClick = () => {
    const newIcon =
      icon === "fas fa-chart-simple"
        ? "fas fa-angle-down"
        : "fas fa-chart-simple";
    setIcon(newIcon);
    clickFun();
  };
  return (
    <>
      <div
        className={
          "border border rounded-3 py-3 bsl-widget px-3 " +
          (data?.only_icon &&
            " text-center " + (data?.cursor && " cursor-pointer "))
        }
        onClick={handleClick}
      >
        {data?.icon_start && (
          <i className={"_start me-2  align-middle " + data?.icon_start}></i>
        )}
        {data?.text && <i className="fas fa-circle coma me-2 align-middle"></i>}
        {data?.text}
        <span className="float-end">{data?.value}</span>
        {data?.value == null && <i className={"" + icon}></i>}
      </div>
    </>
  );
}
export default Widget;
