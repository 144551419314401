import React from "react";
import "./../../styles/Tables/tableTitle.css";
/**
 * Global titile on the content page, with button to create new element on option
 *
 * @param {{title: String, button_name: String,show_button:boolean, onClick: ()=>{}}} props
 * @returns component
 * @author Johns David
 */
function TableTitle({
  title,
  button_name,
  show_button = false,
  onClick,
  modalId = "addNewModal",
}) {
  // data-bs-toggle="modal"
  // data-bs-target={"#" + modalId}
  return (
    <div className="tt-page-title">
      <div className="tt-title">{title}</div>
      {show_button && (
        <button type="button" className="btn btn-primary p-2" onClick={onClick}>
          <i className="tt-mingcute-add-line fas fa-plus"></i> {button_name}
        </button>
      )}
    </div>
  );
}

export default TableTitle;
