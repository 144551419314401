import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: { action_name: "", action_value: null },
};
const btnIconSlice = createSlice({
  name: "btnIconSlice",
  initialState,
  reducers: {
    initUpdate: (state, action) => {
      state.data = {
        action_name: action.payload?.action_name
          ? action.payload?.action_name
          : state.data.action_name,
        action_value: action.payload?.action_value
          ? action.payload?.action_value
          : state.data.action_value,
      };
    },
  },
});

export const { initUpdate } = btnIconSlice.actions;
export default btnIconSlice.reducer;
