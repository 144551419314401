import React from "react";
import "../../styles/Pages/login.css";

const ForgotPassword = () => {
  return (
    <div className="connexion">
      <div className="frame">
        <div className="nav">
          <div className="logo">
            <img className="img" src="img/Logo.svg" />
          </div>
        </div>
      </div>
      <div className="dialog-connexion-wrapper">
        <div className="dialog-connexion">
          <div className="div">
            <p className="bienvenue">Demande de mot de passe</p>
            <p className="s-bastien">
              Un mail vous sera envoyé
              <br />à *******inski@bslogitics.com
            </p>
          </div>
          <div className="frame-2">
            <button className="button-primary">
              <p className="label-2">Renouveler mon mot de passe</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
