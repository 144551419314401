import React, { useState, useEffect, useCallback } from "react";
import "../../styles/NavBar/NavBar.css";
import ItemNav from "./ItemNav";
import { Menu } from "./../../data/NavBar";
import Profile from "./Profile";
import { useUser } from "../../UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useAccess } from "../../hooks/useAccess";

/**
 * Global NavBar
 *
 * @returns component
 * @author Johns David
 */

export const NavBar = () => {
  //Permissions
  const can_access_client_page = useAccess(1);
  const can_access_sous_traitants_page = useAccess(13);
  const can_access_demande_page = useAccess(24);
  const can_access_commande_page = useAccess(39);
  const can_access_admin_page = useAccess(45);

  let filtered_menu = hideMenusByPageAccess();

  const [collapse, setCollapse] = useState(
    JSON.parse(localStorage.getItem("navbarCollapse")) || false
  );
  const { setUser, logout } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;

  useEffect(() => {
    localStorage.setItem("navbarCollapse", JSON.stringify(collapse));
  }, [collapse]);

  const clickMenu = useCallback(
    (code) => {
      navigate("/" + code.replace(/\s+/g, "-"));
    },
    [navigate]
  );

  const clickCollapse = () => {
    setCollapse((prevCollapse) => !prevCollapse);
  };

  // const handleLogout = useCallback(() => {
  //   document.cookie.split(";").forEach((cookie) => {
  //     document.cookie = cookie
  //       .replace(/^ +/, "")
  //       .replace(
  //         /=.*/,
  //         "=;expires=" + new Date().toUTCString() + ";path=/;samesite=Lax"
  //       );
  //   });
  //   localStorage.clear();
  //   setUser(null);
  // }, [setUser]);
  const handleLogout = () => {
    logout(setUser);
  };

  function getFilteredMenu(menu_list = [], menu_to_filter = "", filter = true) {
    if (filter) {
      menu_list = menu_list.filter((menu) => menu.title !== menu_to_filter);
    }
    return menu_list;
  }

  function hideMenusByPageAccess() {
    let filtered_menu = Menu;
    filtered_menu = getFilteredMenu(
      filtered_menu,
      "Clients",
      !can_access_client_page
    );
    filtered_menu = getFilteredMenu(
      filtered_menu,
      "Sous traitants",
      !can_access_sous_traitants_page
    );
    filtered_menu = getFilteredMenu(
      filtered_menu,
      "Demande",
      !can_access_demande_page
    );
    filtered_menu = getFilteredMenu(
      filtered_menu,
      "Commande",
      !can_access_commande_page
    );
    filtered_menu = getFilteredMenu(
      filtered_menu,
      "Admin",
      !can_access_admin_page
    );
    return filtered_menu;
  }

  return (
    <div className="nav-admin">
      <div className="div">
        <div className={"logo " + (collapse && "logo-collapse ")}>
          <img
            className={"img " + (collapse ? "img-collapse" : "img-default")}
            src={collapse ? "img/Logo_collapse.svg" : "img/Logo.svg"}
            alt=""
          />
          <div
            className={
              "cursor-pointer " + (collapse ? "button-collapse" : "button")
            }
            onClick={clickCollapse}
          >
            <img className="img-2" src="img/mingcute_left-small-line.svg" />
          </div>
        </div>
        <div className={"menu " + (collapse && " menu-collapse")}>
          {filtered_menu.map((item) => (
            <ItemNav
              key={item.id}
              image={item.image}
              title={item.title}
              selected={`/${item.title.replace(/\s+/g, "-")}` === activePath}
              end={item.end}
              collapse={collapse}
              onClick={() => clickMenu(item.title)}
            />
          ))}
        </div>
        <div className={"frame " + (collapse && "frame-collapse ")}>
          <Profile collapse={collapse} />
          <div
            className="button-ghostwhite cursor-pointer"
            onClick={handleLogout}
          >
            {!collapse && <div className="label-2">Se déconnecter</div>}
            <img className="img-2" src="img/mingcute_power-line.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
