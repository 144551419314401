/**
 * Get n days in pas after today
 *
 * @param {int} days
 * @returns Date
 * @author Johns David
 */
const getEndDate = (days = 7) => {
  // Get the current date
  var currentDate = new Date();
  // Subtract 7 days from the current date
  var nDaysAgo = new Date(currentDate);
  nDaysAgo.setDate(currentDate.getDate() - days);
  return nDaysAgo;
};
const firstLastDay = (day) => {
  // Get the current date
  var currentDate = new Date(day);
  // Set the date to the first day of the current month
  var firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  // Set the date to the last day of the current month
  var lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  // Format the dates to display in a readable format
  var formattedFirstDay = firstDayOfMonth.toLocaleDateString().split("/");
  var formattedLastDay = lastDayOfMonth.toLocaleDateString().split("/");
  return {
    endDate: `${formattedLastDay[2]}-${formattedLastDay[1]}-${formattedLastDay[0]}`,
    startDate: `${formattedFirstDay[2]}-${formattedFirstDay[1]}-${formattedFirstDay[0]}`,
  };
};

const getMonthYearByDate = (dateString) => {
  // Create a new Date object from the input string
  var date = new Date(dateString);
  // Options for formatting the date
  var options = { month: "long", year: "numeric" };
  // Format the date to "Month Year" format using French locale
  var formattedDate = date.toLocaleDateString("fr-FR", options);
  // Capitalize the first character of the month
  formattedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  return formattedDate;
};

export { getEndDate, firstLastDay, getMonthYearByDate };
