import React from "react";
import "../../styles/Modals/ModalForAddNew.css";
import "../../styles/Form/input.css";

const ModalForAddNew = ({
  children,
  modalData = {
    type: "modal",
    modalId: "addNewModal",
    modalClass: "",
  },
  isShowModale = false,
}) => {
  return (
    <>
      <div
        className={
          "modal fade w-100 h-100 " +
          modalData?.modalClass +
          " " +
          (isShowModale && " show bsl-show-modal ")
        }
        id={modalData?.modalId}
        tabIndex="-1"
        aria-labelledby={modalData?.modalId + "Label"}
        aria-hidden={isShowModale ? null : "true"}
        aria-modal={isShowModale ? "true" : null}
        role={isShowModale ? "dialog" : null}
        style={
          isShowModale == true ? { display: "block" } : { display: "none" }
        }
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-slideout addNewModalDialogCustomSize ps-25 me-0 mt-0 mb-0">
          <div className="modal-content h-100 w-100">{children}</div>
        </div>
      </div>
    </>
  );
};

export default ModalForAddNew;
