import React, { useCallback, useEffect } from "react";
import CheckBox from "../Form/CheckBox";
import { useDispatch } from "react-redux";
import { selectCheck } from "../../store/table/tableSlice";
import TableButtonStatus from "./TableButtonStatus";
import "./../../styles/Tables/tableTd.css";
import TableLabelRound from "./TableLabelRound";
import ProgressBar from "../stats/ProgressBar";
import { initUpdate } from "../../store/table/btnIconSlice";

function TableTd({
  item = {},
  keys = [],
  index,
  list_not_normal = [],
  onSelectCheck,
}) {
  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    dispatch(selectCheck(index));
  };

  const tdAction = useCallback(
    (data) => {
      dispatch(
        initUpdate({
          action_name: data?.param_update?.action_name,
          action_value: data[data?.param_update?.ref_action],
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      {keys.map((d, idx) => (
        <td key={idx}>
          {d === "check" ? (
            <CheckBox value={item.check} onChange={handleCheckboxChange} />
          ) : typeof item[d] === "object" && item[d] !== null ? (
            <OtherFormshow
              data={item}
              cle={d}
              click={() => {
                tdAction(item);
              }}
            />
          ) : (
            <div onClick={() => [tdAction(item)]} className="cursor-pointer">
              {item[d]}
            </div>
          )}
        </td>
      ))}
    </>
  );
}

function OtherFormshow({ data, cle, click = () => {} }) {
  const dispatch = useDispatch();
  const color_statut = (item) => {
    let color = null;
    if (item.color > 0) {
      let ref = [
        "warning",
        "primary",
        "success",
        "danger",
        "secondary",
        "info",
        "light",
        "dark",
      ];
      color = ref[item.color - 1];
    }
    color = color ? color : item.disabled ? "danger" : "success";
    return color;
  };

  function makeAcronymOfFullName(fullName = "") {
    let acronym = "";
    fullName.split(" ").map((name) => {
      acronym += name.charAt(0);
      return name;
    });
    return acronym.substring(0, 2);
  }

  const iconAction = useCallback(
    (data) => {
      dispatch(
        initUpdate({
          action_name: data[cle].action_name,
          action_value: data[data[cle].ref_action],
        })
      );
    },
    [dispatch]
  );
  let content;

  switch (true) {
    case cle === "statut" || data[cle].type === "statut":
      content = (
        <TableButtonStatus
          color={color_statut(data[cle])}
          disabled={data[cle].disabled}
        >
          {data[cle].label}
        </TableButtonStatus>
      );
      break;
    case data[cle].type === "round":
      content = (
        <TableLabelRound index_color={data[cle].color}>
          {data[cle].value}
        </TableLabelRound>
      );
      break;
    case data === "delete":
      content = (
        <button className="btn">
          <i className="fas delete-icon"></i>
        </button>
      );
      break;
    case data[cle].type === "progress":
      content = (
        <ProgressBar
          width={data[cle]?.width}
          value={data[cle]?.value}
          text={data[cle].text}
          color={data[cle].color}
        />
      );
      break;
    case data[cle].type === "rond_label":
      content = (
        <div
          className="d-flex flex-row align-items-center align-text-middle cursor-pointer"
          onClick={click}
        >
          <TableLabelRound
            index_color={2}
            otherClass="text-center fw-medium text-primary-emphasis w-15 h-15"
          >
            {makeAcronymOfFullName(data[cle].value)}
          </TableLabelRound>
          <div className="ms-2">{data[cle].value}</div>
        </div>
      );
      break;
    case data[cle].type === "icon":
      content = (
        <button
          className="btn"
          onClick={() => {
            iconAction(data);
          }}
        >
          <i className={"bsl-color " + data[cle].value}></i>
        </button>
      );
      break;
    default:
      content = <p>None</p>;
  }

  return <>{content}</>;
}

export default TableTd;
